import React, { useRef, useState, useEffect, Fragment } from "react";
import {
  Table,
  Pagination,
  Badge,
  Dropdown,
  Spinner,
  Modal,
} from "react-bootstrap";

import PageTitle from "../../layouts/PageTitle";

import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";

import axiosInstance from "../../../services/AxiosInstance";
import swal from "sweetalert";

import { format } from "date-fns";

import styled from "styled-components";

import { getProfile, profileAction } from "../../../store/actions/AuthActions";
import Swal from "sweetalert2";

//const Orders = () => {
const OrderStatus = ({ props, history, match }) => {
  const dispatch = useDispatch();

  const [orderId, setOrderId] = useState("");
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(false);

  const profile = useSelector((state) => state.auth.profile);

  useEffect(() => {
    console.log("match.params.id", match.params.id);
    dispatch(getProfile());
    setOrderId(match.params.id);
  }, [dispatch]);

  useEffect(() => {
    loadOrderDetails();
  }, [orderId]);

  const loadOrderDetails = async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.post("orders/status", {
        order_id: orderId,
      });

      if (data.status) {
        setOrder(data.order);
      } else {
        setOrder(null);
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <>
      <Fragment>
        <PageTitle motherMenu="Order" activeMenu="Invoice" />

        {loading || profile == null ? (
          <div className="text-center mt-4">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : order == null ? (
          <div className="row">
            <div className="col-lg-12">
              <div className="card mt-3">
                <div className="card-header"> Invoice</div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-xl-12">
                      <div>
                        {" "}
                        <strong>No order details found!!!</strong>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-lg-12">
              <div className="card mt-3">
                <div className="card-header">
                  
                 #{order.id}<strong >
                  {order.paymentStatus == "paid" ?<Badge variant="success light">PAID</Badge>:<Badge variant="danger light">UNPAID</Badge>}
                    </strong>{" "}
                  <span className="float-right">
                    <strong>Status:</strong> {order.status}
                  </span>{" "}
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-xl-4 col-sm-6 mb-4">
                      <h6>From:</h6>
                      <div>
                        {" "}
                        <strong>BITBEAST PVT LTD</strong>{" "}
                      </div>
                      <div>B - 204 2nd Floor, Sumel Business Park 7</div>
                      <div>Soni ni chali, Ahmedabad, Gujarat - 382350</div>
                      <div>Email: info@bitbeast.in</div>
                      <div>Phone: 1800 889 8358</div>
                    </div>
                    <div className="col-xl-4 col-sm-6 mb-4">
                      <h6>To:</h6>
                      <div>
                        {" "}
                        <strong>{profile.name}</strong>{" "}
                      </div>
                      {profile.address ? (
                        <>
                          <div>{profile?.address?.addressLine1 ?? ""}</div>
                          <div>{profile?.address?.addressLine2 ?? ""}</div>
                          <div>
                            {profile?.address?.city ?? ""},{" "}
                            {profile?.address?.state ?? ""} -{" "}
                            {profile?.address?.zip ?? ""}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      <div>Email: {profile.email ?? ""}</div>
                      <div>Phone: {profile.phone ?? ""}</div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 mb-4 d-flex justify-content-lg-end justify-content-md-center justify-content-xs-start">
                      <div className="row align-items-center">
                        <div className="col-sm-9">
                          <div className="brand-logo mb-3">
                            <img
                              className="logo-abbr mr-2"
                              width={200}
                              src="https://api.digibulkmarketing.com/media/reseller/dbm/logo.png"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th className="center">#</th>
                          <th>Item</th>
                          <th>Description</th>
                          <th className="right">Unit Cost</th>
                          <th className="center">Qty</th>
                          <th className="right">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="center">1</td>
                          <td className="left strong">
                            {(
                              order.packId.type +
                              " " +
                              order.packId.name
                            ).toUpperCase()}
                          </td>
                          <td className="left">
                            {order.packId.description} - {order.packId.counter}{" "}
                            Wallet Point
                          </td>
                          <td className="right">
                            {order.currency} {order.orderTotal}
                          </td>
                          <td className="center">1</td>
                          <td className="right">
                            {order.currency} {order.orderTotal}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-sm-5"> </div>
                    <div className="col-lg-4 col-sm-5 ml-auto">
                      <table className="table table-clear">
                        <tbody>
                          <tr>
                            <td className="left">
                              <strong>Total</strong>
                            </td>
                            <td className="right">
                              <strong>
                                {order.currency} {order.orderTotal}
                              </strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    </>
  );
};

export default OrderStatus;
