import React, { Component } from "react";

/// Link
import { Link } from "react-router-dom";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Menu
import MetisMenu from "metismenujs";

///
import drump from "../../../images/card/drump.png";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
    // this.mm("dispose");
    // console.log(this.mm);
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");

    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }

    btn.addEventListener("click", toggleFunc);
  }
  render() {
    /// Path
    var fullPath = window.location.pathname;
    let path = fullPath.split("/");
    path = path[path.length - 1];

    /// Active menu
    let deshBoard = [
        "",
        // "workout-statistic",
        // "workout-plan",
        // "distance-map",
        // "diet-food-menu",
        // "personal-record",
      ],
      campaign = ["voucher"];
    /*   app = [
            "app-profile",
            "post-details",
            "app-calender",
            "email-compose",
            "email-inbox",
            "email-read",
            "ecom-product-grid",
            "ecom-product-list",
            "ecom-product-list",
            "ecom-product-order",
            "ecom-checkout",
            "ecom-invoice",
            "ecom-customers",
            "post-details",
            "ecom-product-detail",
         ],
         email = ["email-compose", "email-inbox", "email-read"],
         shop = [
            "ecom-product-grid",
            "ecom-product-list",
            "ecom-product-list",
            "ecom-product-order",
            "ecom-checkout",
            "ecom-invoice",
            "ecom-customers",
            "ecom-product-detail",
         ],
         charts = [
            "chart-rechart",
            "chart-flot",
            "chart-chartjs",
            "chart-chartist",
            "chart-sparkline",
            "chart-apexchart",
         ],
         bootstrap = [
            "ui-accordion",
            "ui-badge",
            "ui-alert",
            "ui-button",
            "ui-modal",
            "ui-button-group",
            "ui-list-group",
            "ui-media-object",
            "ui-card",
            "ui-carousel",
            "ui-dropdown",
            "ui-popover",
            "ui-progressbar",
            "ui-tab",
            "ui-typography",
            "ui-pagination",
            "ui-grid",
         ],
         plugins = [
            "uc-select2",
            "uc-nestable",
            "uc-sweetalert",
            "uc-toastr",
            "uc-noui-slider",
            "map-jqvmap",
			"uc-lightgallery",
         ],
         widget = ["widget-basic"],
         forms = [
            "form-element",
            "form-wizard",
            "form-editor-summernote",
            "form-pickers",
            "form-validation-jquery",
         ],
         table = ["table-bootstrap-basic", "table-datatable-basic"],
         pages = [
            "page-registser",
            "page-login",
            "page-lock-screen",
            "page-error-400",
            "page-error-403",
            "page-error-404",
            "page-error-500",
            "page-error-503",
         ],
         error = [
            "page-error-400",
            "page-error-403",
            "page-error-404",
            "page-error-500",
            "page-error-503",
         ]; */
    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            <li className={`${path === "" ? "mm-active" : ""}`}>
              <Link to="/" className="ai-icon">
                <i className="flaticon-381-home-2"></i>
                <span className="nav-text">Dashboard</span>
              </Link>
            </li>

            <li className={`${path === "resellers" ? "mm-active" : ""}`}>
              <Link to="/resellers" className="ai-icon">
                <i className="flaticon-381-user-9"></i>
                <span className="nav-text">Resellers</span>
              </Link>
            </li>

            <li className={`${fullPath.includes("license-keys") ? "mm-active" : ""}`}>
              <Link className="ai-icon has-arrow" to="#">
                <i className="flaticon-381-key"></i>

                <span className="nav-text">License Keys</span>
              </Link>
              <ul className={`${fullPath.includes("license-keys") ? "mm-show" : ""}`}>
                <li>
                  <Link
                    className={`${
                      fullPath.includes("license-keys/all") ? "mm-active" : ""
                    }`}
                    to="/license-keys/all"
                  >
                    All Keys
                  </Link>

                  <Link
                    className={`${
                      fullPath.includes("license-keys/available") ? "mm-active" : ""
                    }`}
                    to="/license-keys/available"
                  >
                    Availables
                  </Link>

                  <Link
                    className={`${
                      fullPath.includes("license-keys/active") ? "mm-active" : ""
                    }`}
                    to="/license-keys/active"
                  >
                    Activated
                  </Link>

                  <Link
                    className={`${
                      fullPath.includes("license-keys/expired") ? "mm-active" : ""
                    }`}
                    to="/license-keys/expired"
                  >
                    Expired
                  </Link>

                  <Link
                    className={`${
                      fullPath.includes("license-keys/trial") ? "mm-active" : ""
                    }`}
                    to="/license-keys/trial"
                  >
                    Trial
                  </Link>

                </li>
              </ul>
            </li>

            <li className={`${path === "wallet-record" ? "mm-active" : ""}`}>
              <Link to="/wallet-record" className="ai-icon">
                <i className="flaticon-381-id-card"></i>
                <span className="nav-text">Wallet Record</span>
              </Link>
            </li>

            {!process.env.REACT_APP_MASTER_RESELLER_ID?
            <li className={`${path === "products" ? "mm-active" : ""}`}>
              <Link to="/products" className="ai-icon">
                <i className="flaticon-381-list-1"></i>
                <span className="nav-text">Products</span>
              </Link>
            </li>:<></>}

            {!process.env.REACT_APP_MASTER_RESELLER_ID? <li className={`${path === "packs" ? "mm-active" : ""}`}>
                     <Link
                        to="/packs"
                        className="ai-icon"
                        
                     >
                        
                        <i className="flaticon-381-briefcase"></i>
                        <span className="nav-text">Packs</span>
                     </Link>
                  </li>:<></>}

            {/* <li className={`${path === "packs" ? "mm-active" : ""}`}>
                     <Link
                        to="/packs"
                        className="ai-icon"
                        
                     >
                        
                        <i className="flaticon-381-briefcase"></i>
                        <span className="nav-text">Packs</span>
                     </Link>
                  </li> */}

            <li className={`${path === "feedback" ? "mm-active" : ""}`}>
              <Link to="/feedback" className="ai-icon">
                <i className="flaticon-381-help-1"></i>
                <span className="nav-text">Feedback</span>
              </Link>
            </li>

            <li className={`${path === "setting" ? "mm-active" : ""}`}>
              <Link to="/setting" className="ai-icon">
                <i className="flaticon-381-settings-6"></i>

                <span className="nav-text">Setting</span>
              </Link>
            </li>

            {process.env.REACT_APP_SHOW_TERMS == "true"?
            <li>


<a target={"_blank"} href="https://digibulkmarketing.com/terms-conditions/" className="ai-icon">
  <i className="flaticon-381-earth-globe-1"></i>
  <span className="nav-text">Terms And Conditions</span>
</a>

</li>:<></>}

{process.env.REACT_APP_SHOW_TERMS == "true"?
<li>

<a target={"_blank"} href="https://digibulkmarketing.com/privacy-policy/" className="ai-icon">
  <i className="flaticon-381-earth-globe-1"></i>
  <span className="nav-text">Privacy Policy</span>
</a>

</li>:<></>}

           {/*  <li className={`${campaign.includes(path) ? "mm-active" : ""}`}>
              <Link className="ai-icon has-arrow" to="#">
                <i className="flaticon-381-promotion"></i>

                <span className="nav-text">Campaign</span>
              </Link>
              <ul className={`${campaign.includes(path) ? "mm-show" : ""}`}>
                <li>
                  <Link
                    className={`${path === "vouchers" ? "mm-active" : ""}`}
                    to="/vouchers"
                  >
                    Vouchers
                  </Link>
                </li>
              </ul>
            </li> */}
          </MM>

          <div className="copyright">
          <p>
            <strong>{process.env.REACT_APP_NAME}</strong> ©All Rights Reserved
          </p>
          <p>By {process.env.REACT_APP_BY}</p>
        </div>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default SideBar;
