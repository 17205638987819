import React, { useRef, Fragment } from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import axiosInstance from "../../../services/AxiosInstance";
import swal from "sweetalert";
import { format } from "date-fns";

import { TwitterPicker, SketchPicker, SliderPicker } from "react-color";

import {
  Table,
  Pagination,
  Badge,
  Dropdown,
  Spinner,
  Button,
  Modal,
  Row,
  Col,
  Form,
  InputGroup,
  FormControl,
  Alert,
} from "react-bootstrap";

import { CSVLink } from "react-csv";

import moment from "moment";

import {
  DateTimePicker,
  MuiPickersUtilsProvider,
  DatePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import DTable from "../../components/Table";

import noIamage from "../../../images/no-image-uploaded.png";

import PhoneInput from "react-phone-number-input";
import { getAllProducts } from "../../../store/actions/licActions";
import { profileAction } from "../../../store/actions/AuthActions";

const AgentDetails = ({ match, history }) => {
  const dispatch = useDispatch();
  const [reseller, setReseller] = useState(null);
  const [members, setMembers] = useState([]);
  const [xlsData, setXlsData] = useState([]);
  const [agents, setAgents] = useState([]);

  const profile = useSelector((state) => state.auth.profile);
  const [appData, setAppData] = useState({});

  const products = useSelector((state) => state.lic.products);
  const [selectedProduct, setSelectedProduct] = useState("select");

  const [counter, setCounter] = useState("");
  const [allotCounterLoading, setAllotCounterLoading] = useState(false);

  const [activeToggle, setActiveToggle] = useState("members");
  const [sendMessage, setSendMessage] = useState(false);

  const [replay, setReplay] = useState(false);
  const [loading, setLoading] = useState(false);

  const [keys, setKeys] = useState([]);

  const [errors, setErrors] = useState({});
  const [generateKeyModal, setGenerateKeyModal] = useState(false);
  const [newCampaign, setNewCampaign] = useState(false);
  const [campaignName, setCampaignName] = useState("");
  const [selectedCampaign, setSelectedCampaign] = useState("");

  const [keyQty, setKeyQty] = useState(1);
  const [valid, setValid] = useState(365);
  const [note, setNote] = useState("");

  const [activeKey, setActiveKey] = useState(null);
  const [editKeyModal, setEditKeyModal] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [enable, setEnable] = useState(false);
  const [rebranding, setRebranding] = useState(false);
  const [expireDate, setExpireDate] = useState(new Date());
  const [activeDate, setActiveDate] = useState(new Date());

  const [status, setStatus] = useState("0");

  const [product, setProduct] = useState("all");
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);

  const [softwareName, setSoftwareName] = useState("");
  const [description, setDescription] = useState("");

  const [helpContact, setHelpContact] = useState("");
  const [helpEmail, setHelpEmail] = useState("");

  const [siteLink, setSiteLink] = useState("");
  const [demoVideoLink, setDemoVideoLink] = useState("");

  const [showAd, setShowAd] = useState(false);
  const [adBannerLink, setBannerAdLink] = useState("");
  const [adLink, setAdLink] = useState("");

  const [rebrand, setRebrand] = useState(false);

  const [color, setColor] = useState({ hex: "#08094e" });

  const [filter, setFilter] = useState("all");

  const [editMemberModal, setEditMemberModal] = useState(false);

  const [forceUpdate, setForceUpdate] = useState(false);
  const [version, setVersion] = useState(1);
  const [updateUrl, setUpdateUrl] = useState("");

  const csvInstance = useRef(null);
  useEffect(() => {
    if (
      xlsData &&
      csvInstance &&
      csvInstance.current &&
      csvInstance.current.link
    ) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setXlsData([]);
      });
    }
  }, [xlsData]);

  //rebranding
  const [rebrandModal, setRebrandModal] = useState(false);

  useEffect(() => {
    dispatch(getAllProducts());
    loadProfile();
    loadAppData();
  }, [dispatch]);

  async function loadProfile() {
    try {
      const { data } = await axiosInstance.get("auth/profile");

      console.log("profile data - " + JSON.stringify(data));

      if (data.status === false) {
      } else {
        //setUser(data.user);
        dispatch(profileAction(data.profile));
        console.log("profile data - " + JSON.stringify(data));
      }
    } catch (error) {}
  }

  async function loadAppData() {
    try {
      const { data } = await axiosInstance.get("app");

      console.log("app data - " + JSON.stringify(data));

      if (data.status === false) {
      } else {
        //setUser(data.user);
        setAppData(data.data);
        console.log("profile data - " + JSON.stringify(data));
      }
    } catch (error) {}
  }

  useEffect(() => {}, [match]);

  useEffect(() => {
    if (keys) {
      setLoading(false);
    }
  }, [keys]);

  useEffect(() => {
    if (reseller) {
      console.log("reseller.rebrandProduct - " + JSON.stringify(reseller));

      if (reseller.rebrandProducts.includes(selectedProduct)) {
        setEnable(true);
        setRebranding(true);
        loadRebrandDetails(selectedProduct, match.params.id);
      } else {
        setEnable(false);
        setRebranding(false);
      }

      // var product = products.find((x) => x._id == selectedProduct);
      // if (product) {

      // }else{
      //   setEnable(false);
      //   setRebranding(false);
      // }
    } else {
      setEnable(false);
      setRebranding(false);
    }
  }, [selectedProduct]);

  const loadRebrandDetails = async (product_id, reseller_id) => {
    const { data } = await axiosInstance.post(
      "master-reseller/rebrand-detail",
      {
        product_id: product_id,
        reseller_id: reseller_id,
      }
    );

    if (data.detail) {
      setSoftwareName(data.detail.name ?? "");
      setDescription(data.detail.description ?? "");

      setHelpContact(data.detail.contactNumber);
      setHelpEmail(data.detail.email);

      setShowAd(data.detail.showAd);
      setBannerAdLink(data.detail.adBannerUrl);
      setAdLink(data.detail.adUrl);

      setSiteLink(data.detail.siteUrl);
      setDemoVideoLink(data.detail.demoVideoUrl);

      setColor({ hex: data.detail.color });
      setEnable(data.detail.enable);

      setVersion(data.detail.version);
      setForceUpdate(data.detail.forceUpdate);
      setUpdateUrl(data.detail.updateUrl);
    } else {
      setSoftwareName("");
      setDescription("");

      setHelpContact("");
      setHelpEmail("");

      setShowAd(false);
      setBannerAdLink("");
      setAdLink("");

      setSiteLink("");
      setDemoVideoLink("");

      setEnable(true);

      setVersion("");
      setForceUpdate("");
      setUpdateUrl("");
    }

    console.log("details data - " + JSON.stringify(data));
  };

  useEffect(() => {
    loadResellerDetails(match.params.id);
  }, [match]);

  const loadResellerDetails = async (userId) => {
    const { data } = await axiosInstance.get(
      "master-reseller/reseller/" + userId
    );

    if (data.status) {
      setReseller(data.reseller);
      //setKeys(data.reseller.keys);

      document.getElementById("nav_title").innerHTML = data.reseller.name
        ? data.reseller.name ?? ""
        : data.reseller.email ?? "";
    }
  };

  function productName(id) {
    if (id) {
      const productIndex = products.findIndex((x) => x._id === id);

      if (productIndex != -1) {
        return products[productIndex].name;
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  const onDeactive = async (code) => {};

  const onChangeLicStatus = async (lic, status) => {
    const { data } = await axiosInstance.post(
      "master-reseller/change-lic-status",
      {
        license_id: lic._id,
        status: status,
      }
    );

    if (data.status === false) {
      //toast.error(data.message);
      swal("Change license Status", data.message, "error");
    } else {
      swal("Change license Status", data.message, "success");
      // setTimeout(() => {
      //   loadKeys();
      // }, 500);

      var dummy = [...keys];

      const index = dummy.findIndex((x) => x._id === lic._id);

      dummy[index].enable = status;
      setKeys(dummy);
    }
  };

  function keyStatusText(d) {
    if (d.status == 0) {
      return "Available";
    } else if (d.status == 1) {
      return "Active";
    } else if (d.status == 2) {
      return "Deactive";
    } else if (d.status == 3) {
      return "Expire";
    } else {
      return "";
    }
  }

  const initXlsData = async () => {
    const { data } = await axiosInstance.post("master-reseller/all-licenses", {
      filter: filter ?? "all",
      product_id: product,
      reseller_id: match.params.id,
      search: search,
      page: 0,
      limit: maxPage * pageSize,
    });

    if (data.status) {
      console.log("total keys - " + data.keys.length);

      var dummy = [];
      data.keys.map((d, i) => {
        dummy.push({
          sr_no: i + 1,
          id: d.id,
          key: d.key,
          product_id: d.productId,
          product_name: productName(d.productId),
          valid: d.valid,
          status: keyStatusText(d),
          name: d.name,
          phone: d.phone,
          email: d.email,
          place: d.place,
          country: d.country,
          activate_at: d.activateAt
            ? format(new Date(d.activateAt), "dd/MM/yyyy H:mma")
            : "",
          expire_at: d.expireAt
            ? format(new Date(d.expireAt), "dd/MM/yyyy H:mma")
            : "",
          note: d.note ?? "",
          created_at: format(new Date(d.createdAt), "dd/MM/yyyy H:mma"),
        });
      });

      setXlsData(dummy);
    }
  };

  function keyStatus(d) {
    if (d.status == 0) {
      return <Badge variant="success light">Available</Badge>;
    } else if (d.status == 1) {
      return <Badge variant="secondary">Active</Badge>;
    } else if (d.status == 2) {
      return <Badge variant="danger light">Deactive</Badge>;
    } else if (d.status == 3) {
      return <Badge variant="danger light">Expire</Badge>;
    } else {
      return <></>;
    }
  }

  // Data Table Start
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = useState(10);
  const pageSizes = [10, 25, 50, 100];
  const [maxPage, setMaxPage] = useState(1);

  const handlePageChange = (value) => {
    setPage(value);
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setPage(0);
  };

  useEffect(() => {
    loadKeys(filter);
  }, [page, pageSize]);

  // Data Table End

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    setSearchTimeout(
      setTimeout(() => {
        loadKeys(filter);
      }, 1000)
    );

    return () => clearTimeout(searchTimeout);
  }, [search]);

  const loadKeys = async (filter) => {
    setLoading(true);
    const { data } = await axiosInstance.post("master-reseller/all-licenses", {
      filter: filter ?? "all",
      product_id: product,
      reseller_id: match.params.id,
      search: search,
      page: page,
      limit: pageSize,
    });

    if (data.status) {
      setKeys(data.keys);
      var maxPage2 = Math.ceil(data.total / pageSize);
      console.log("max page - " + maxPage2);
      setMaxPage(maxPage2);
      setLoading(false);
    }
  };

  const columns = [
    {
      Header: "SN",
      accessor: "_id",
      sort: false,
      accessor: "",

      Cell: (row) => {
        return <div>{Number(row.row.id) + page * pageSize + 1}</div>;
      },
    },
    {
      Header: "Key",
      accessor: "key",
      //colspan:2,
      //width: getColumnWidth(rows, 'key', 'Key'),
      //style: { width: '40%', textAlign: 'center' },
      Cell: ({ row }) => {
        const d = row.original;
        return (
          <span
            style={{ whiteSpace: "nowrap" }}
            onClick={() => {
              navigator.clipboard.writeText(d.key);
            }}
          >
            {d.key}
          </span>
        );
        //return <input  className="form-control" style={{ width: '40%', textAlign: 'center' }} type="text" value={d.key} readOnly={true}></input>;
      },
    },
    {
      Header: "User",
      accessor: "name",
      sort: false,
      Cell: ({ row }) => {
        const d = row.original;
        return (
          <div>
            {d.name}
            <br />
            <small>{d.phone}</small>
            <br />
            <small>{d.email}</small>
            <br />
            <small>{d.place}</small>
            <br />
            <small>{d.country}</small>
          </div>
        );
      },
    },

    {
      Header: "Product",
      accessor: "productId",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return productName(d.productId);
      },
    },

    {
      Header: "Campaign",
      accessor: "campaignId",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return d.campaignId ? d.campaignId.name : "";
      },
    },

    {
      Header: "Valid",
      accessor: "valid",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return d.valid + " Days";
      },
    },

    {
      Header: "Status",
      accessor: "status",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return <div className="text-center">{keyStatus(d)}</div>;
      },
    },

    {
      Header: "Enable",
      accessor: "enable",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return (
          <div className="text-center">
            {d.enable ? (
              <Badge variant="success light">Yes</Badge>
            ) : (
              <Badge variant="danger light">No</Badge>
            )}
          </div>
        );
      },
    },

    {
      Header: "Active",
      accessor: "activatedAt",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return d.activateAt
          ? format(new Date(d.activateAt), "dd/MM/yyyy H:mma")
          : "No";
      },
    },

    {
      Header: "Expire",
      accessor: "expireAt",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return d.expireAt
          ? format(new Date(d.expireAt), "dd/MM/yyyy H:mma")
          : "No";
      },
    },

    {
      Header: "Note",
      accessor: "note",
      sort: true,
    },

    {
      Header: "Created",
      accessor: "createdAt",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return d.createdAt
          ? format(new Date(d.createdAt), "dd/MM/yyyy H:mma")
          : "No";
      },
    },

    {
      Header: "Action",
      accessor: "",
      sort: false,
      Cell: ({ row }) => {
        const d = row.original;

        return (
          <Dropdown>
            <Dropdown.Toggle variant="" className="table-dropdown icon-false">
              <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <rect x="0" y="0" width="24" height="24"></rect>
                  <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                </g>
              </svg>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {d.enable ? (
                <Dropdown.Item
                  as="button"
                  onClick={(e) => onChangeLicStatus(d, false)}
                >
                  Disable
                </Dropdown.Item>
              ) : (
                <Dropdown.Item
                  as="button"
                  onClick={(e) => onChangeLicStatus(d, true)}
                >
                  Enable
                </Dropdown.Item>
              )}

              {d.status == 1 ? (
                <Dropdown.Item as="button" onClick={(e) => onRemoveDevice(d)}>
                  Remove Device
                </Dropdown.Item>
              ) : (
                <></>
              )}
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ];

  const onRemoveDevice = async (lic) => {
    const { data } = await axiosInstance.post(
      "master-reseller/remove-lic-device",
      {
        license_id: lic.id,
      }
    );

    if (data.status === false) {
      swal("Remove Device", data.message, "error");
    } else {
      swal("Remove Device", data.message, "success");

      var dummy = [...keys];
      const index = dummy.findIndex((x) => x.id === lic.id);
      dummy[index].status = 0;
      setKeys(dummy);
    }
  };

  const changeKeyFilter = async (filter) => {
    await setFilter(filter);
    loadKeys(filter);
  };

  const onSaveDetails = async (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = {};

    if (softwareName === "") {
      errorObj.name = "Software Name is Required";
      error = true;
    }

    if (color.hex === "") {
      errorObj.color = "Color Hex Code is Required";
      error = true;
    }

    if (helpContact === "") {
      errorObj.contact = "Contact Number is Required";
      error = true;
    }

    if (helpEmail === "") {
      errorObj.email = "Email is Required";
      error = true;
    }

    if (siteLink === "") {
      errorObj.siteLink = "Website Link is Required";
      error = true;
    } else if (!siteLink.startsWith("http")) {
      errorObj.siteLink = "Enter valid Website Link";
      error = true;
    }

    if (demoVideoLink === "") {
      errorObj.demoVideoLink = "Demo Video Link is Required";
      error = true;
    } else if (!demoVideoLink.startsWith("http")) {
      errorObj.demoVideoLink = "Enter valid Website Link";
      error = true;
    }

    if (showAd) {
      if (adBannerLink === "") {
        errorObj.adBannerLink = "Ad Banner Image is Required";
        error = true;
      }

      if (adLink === "") {
        errorObj.adLink = "Ad Action Link is Required";
        error = true;
      } else if (!adLink.startsWith("http")) {
        errorObj.adLink = "Enter valid Ad Action Link";
        error = true;
      }
    }

    // if (version === "") {
    //   errorObj.version = "Software version Required";
    //   error = true;
    // }

    // if (updateUrl === "") {
    //   errorObj.updateUrl = "Software Update Link is Required";
    //   error = true;
    // } else if (!updateUrl.startsWith("http")) {
    //   errorObj.updateUrl = "Enter valid Software Update Link";
    //   error = true;
    // }

    setErrors(errorObj);
    if (error) {
      console.log("error on save");
      return;
    }
    console.log("no error on save");
    //save data

    const formData = {
      product_id: selectedProduct,
      reseller_id: match.params.id,
      enable: enable,

      name: softwareName,
      description: "",
      color: color.hex,

      contact_number: helpContact,
      email: helpEmail,

      show_ad: showAd,
      ad_banner_url: adBannerLink,
      ad_url: adLink,

      site_url: siteLink,
      demo_video_url: demoVideoLink,

      // version:version,
      // force_update:forceUpdate,

      // update_url:updateUrl
    };

    console.log("form data - " + formData);

    const { data } = await axiosInstance.post(
      "master-reseller/save-rebrand-detail",
      formData
    );

    if (data.status) {
      swal("Update Rebrand Setting", data.message, "success");
    } else {
      swal("Update Rebrand Setting", data.message, "error");
    }
  };

  const fileChangedHandler = async (event) => {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      try {
        //upload image
        var file = event.target.files[0];

        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        const formData = new FormData();

        formData.append("image", file, file.name);
        formData.append("type", "banner");

        const { data } = await axiosInstance.post(
          "admin/upload-image",
          formData
        );

        //console.log("image upload response - "+data);
        console.log("image upload response - " + JSON.stringify(data));

        if (data.status) {
          swal("Image Upload", data.message, "success");
          setBannerAdLink(data.downloadUrl);
        } else {
          swal("Image Upload", data.message, "error");
        }
      } catch (err) {
        console.log(err);
        //setProfileImage("");
      }
    }
  };

  const fileClickHandler = (event) => {
    const { target = {} } = event || {};
    target.value = "";
  };

  const upload = (e) => {
    e.preventDefault();
    document.getElementById("selectFile").click();
  };

  //edit
  function onEditMember() {
    setPhone(reseller.phone);
    setEmail(reseller.email);
    setName(reseller.name);
    setRebrand(reseller.rebrand);

    setEditMemberModal(true);
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const onSaveMember = async (event) => {
    event.preventDefault();
    let error = false;
    var errorObj = {};

    if (name === "") {
      error = true;
      errorObj.name = "Name is Required";
    }

    if (email === "") {
      error = true;
      errorObj.email = "Email is Required";
    } else if (!validateEmail(email)) {
      error = true;
      errorObj.email = "Enter Valid Email!";
    }

    setErrors(errorObj);
    if (!error) {
      const { data } = await axiosInstance.post(
        "master-reseller/update-reseller",
        {
          name: name,
          rebrand: rebrand,
          email: email,
          user_id: match.params.id,
        }
      );

      if (data.status === false) {
        swal("Update Reseller Details", data.message, "error");
      } else {
        setEditMemberModal(false);
        swal("Update Reseller Details", data.message, "success");

        setTimeout(() => {
          loadResellerDetails(match.params.id);
        }, 500);
      }
    }
  };

  const onActiveRebranding = async () => {
    try {
      const { data } = await axiosInstance.post(
        "master-reseller/buy-rebranding",
        {
          reseller_id: match.params.id,
          product_id: selectedProduct,
        }
      );

      if (data.status === false) {
        swal("Buy Rebranding", data.message, "error");
      } else {
        setEditMemberModal(false);
        swal("Buy Rebranding", data.message, "success");

        //
        const pId = selectedProduct;
        setSelectedProduct("select");
        loadProfile();
        loadResellerDetails(match.params.id);
        setTimeout(() => {
          setSelectedProduct(pId);
          loadRebrandDetails(pId, match.params.id);
        }, 1000);
      }
    } catch (error) {}
  };

  return (
    <>
      <Modal
        className="modal fade"
        show={editMemberModal}
        onHide={setEditMemberModal}
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={onSaveMember}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Edit Reseller Details</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setEditMemberModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Name</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          value={name}
                          className="form-control"
                          autocomplete="off"
                          name="name"
                          required="required"
                          onChange={(e) => setName(e.target.value)}
                          placeholder="Name"
                        />
                        {errors.name && (
                          <div className="text-danger fs-12">{errors.name}</div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Email</label>
                      <div className="contact-name">
                        <input
                          type="email"
                          value={email}
                          className="form-control"
                          autocomplete="off"
                          name="name"
                          required="required"
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Email"
                        />
                        {errors.email && (
                          <div className="text-danger fs-12">
                            {errors.email}
                          </div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">
                        Phone Number
                      </label>
                      <div className="contact-name">
                        <PhoneInput
                          placeholder="Enter phone number"
                          value={phone}
                          defaultCountry="IN"
                          disabled
                          readOnly
                          onChange={setPhone}
                        />

                        {errors.phone && (
                          <div className="text-danger fs-12">
                            {errors.phone}
                          </div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setEditMemberModal(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Fragment>
        <div className="row">
          <div className="col-lg-12">
            {reseller == null ? (
              <div className="text-center mt-4">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="profile card card-body px-3 pt-3 pb-0">
                      <div className="profile-head">
                        <div className="photo-content"></div>
                        <div className="profile-info">
                          <div className="profile-photo"></div>
                          <div className="profile-details">
                            <div className="profile-name px-3 pt-2">
                              <h4 className="text-primary mb-0">
                                {reseller.name}
                              </h4>
                              <p>{reseller.phone}</p>
                              <p>{reseller.email}</p>
                            </div>

                            <div className="profile-name px-3 pt-2">
                              <h4 className="text-primary mb-0 text-center">
                                {reseller.keys}
                                <small className="text-dark">
                                  <br />
                                  Total
                                  <br />
                                  Keys
                                </small>
                              </h4>
                            </div>

                            <div className="profile-name px-3 pt-2">
                              <h4 className="text-primary mb-0 text-center">
                                {reseller.availableKeys}
                                <small className="text-dark">
                                  <br />
                                  Available
                                  <br />
                                  Keys
                                </small>
                              </h4>
                            </div>

                            <div className="profile-name px-3 pt-2">
                              <h4 className="text-primary mb-0 text-center">
                                {reseller.activeKeys}
                                <small className="text-dark">
                                  <br />
                                  Active
                                  <br />
                                  Keys
                                </small>
                              </h4>
                            </div>

                            <div className="profile-name px-3 pt-2">
                              <h4 className="text-primary mb-0 text-center">
                                {reseller.expireKeys}
                                <small className="text-dark">
                                  <br />
                                  Expire
                                  <br />
                                  Keys
                                </small>
                              </h4>
                            </div>

                            <div className="profile-name px-3 pt-2">
                              <h4 className="text-primary mb-0 text-center">
                                {reseller.trialKeys}
                                <small className="text-dark">
                                  <br />
                                  Trial
                                  <br />
                                  Keys
                                </small>
                              </h4>
                            </div>

                            <div className="profile-name ml-auto">
                              <Button
                                className="btn btn-primary font-w600 mr-2"
                                onClick={() => onEditMember()}
                              >
                                Edit Profile
                              </Button>

                              {xlsData.length > 0 ? (
                                <CSVLink
                                  ref={csvInstance}
                                  data={xlsData}
                                  filename={"keys-" + match.params.id + ".csv"}
                                  className="btn btn-primary"
                                  target="_blank"
                                  // asyncOnClick={true}
                                  // onClick={async (event, done) => {
                                  //   console.log("You click the link");
                                  //   await initXlsData();
                                  //   done(); // 👍🏻 You are stopping the handling of component
                                  // }}
                                >
                                  Export
                                </CSVLink>
                              ) : (
                                <Button
                                  className="btn btn-primary font-w600 mr-2"
                                  onClick={() => initXlsData()}
                                >
                                  Export
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title mr-auto">Licenses Keys</h4>

                        <div className="input-group search-area d-lg-inline-flex d-none mr-2">
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              if (searchTimeout) {
                                clearTimeout(searchTimeout);
                              }
                            }}
                          >
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search here"
                              onChange={(e) => {
                                setSearch(e.target.value);
                              }}
                            />
                          </form>
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <svg
                                width={20}
                                height={20}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"
                                  fill="#A4A4A4"
                                />
                              </svg>
                            </span>
                          </div>
                        </div>

                        <Dropdown className="mr-2">
                          <Dropdown.Toggle variant="light" id="dropdown-basic">
                            {filter} Keys
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={(e) => changeKeyFilter("all")}
                            >
                              All Keys
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => changeKeyFilter("available")}
                            >
                              Available Keys
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => changeKeyFilter("active")}
                            >
                              Active Keys
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => changeKeyFilter("expired")}
                            >
                              Expired Keys
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => changeKeyFilter("trial")}
                            >
                              Trial Keys
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div className="card-body">
                        {loading ? (
                          <div className="text-center mt-4">
                            <Spinner animation="border" variant="primary" />
                          </div>
                        ) : keys.length == 0 ? (
                          <p>No License Keys Found</p>
                        ) : keys.length == 0 ? (
                          <p>No keys found</p>
                        ) : (
                          <DTable
                            columns={columns}
                            data={keys}
                            pageSize={pageSize}
                            isSortable={true}
                            pagination={false}
                            pageSizes={pageSizes}
                            page={page}
                            maxPage={maxPage}
                            handlePageChange={handlePageChange}
                            handlePageSizeChange={handlePageSizeChange}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title mr-auto">Rebrand Setting</h4>
                      </div>

                      <div className="card-body">
                        <div id="">
                          <div className="pt-3">
                            <div className="settings-form">
                              <form onSubmit={onSaveDetails}>
                                <div className="form-row">
                                  <div className="form-group mb-3">
                                    <label className="text-black font-w500">
                                      Product
                                    </label>
                                    <select
                                      className="form-control"
                                      value={selectedProduct}
                                      onChange={(e) =>
                                        setSelectedProduct(e.target.value)
                                      }
                                    >
                                      <option value="select">
                                        Choose Product
                                      </option>
                                      {products
                                        .filter((x) => x.enable == true)
                                        .map((p, i) => {
                                          return (
                                            <option key={i} value={p.id}>
                                              {i + 1} - {p.name}
                                            </option>
                                          );
                                        })}
                                    </select>

                                    {errors.product && (
                                      <div className="text-danger fs-12">
                                        {errors.product}
                                      </div>
                                    )}
                                  </div>
                                </div>

                                {selectedProduct != "select" ? (
                                  rebranding ? (
                                    <>
                                      <div className="form-row">
                                        <div className="form-group col-md-6">
                                          <label className="required-field">
                                            Software Name
                                          </label>
                                          <input
                                            type="text"
                                            value={softwareName}
                                            onChange={(e) =>
                                              setSoftwareName(e.target.value)
                                            }
                                            placeholder="Name"
                                            className="form-control"
                                          />
                                          {errors.name && (
                                            <div className="text-danger fs-12">
                                              {errors.name}
                                            </div>
                                          )}
                                        </div>
                                        <div className="form-group col-md-6">
                                          <label>Software Color</label>

                                          <div className="row">
                                            <div className="form-group col-md-6">
                                              <TwitterPicker
                                                color={color}
                                                triangle="hide"
                                                onChangeComplete={setColor}
                                              />
                                            </div>
                                            <div className="form-group ml-auto col-md-4">
                                              <div
                                                style={{
                                                  height: "100px",
                                                  background: color.hex,
                                                  borderRadius:
                                                    "6px 6px 6px 6px",
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                  position: "relative",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    fontSize: "18px",
                                                    color: "rgb(255, 255, 255)",
                                                    position: "relative",
                                                  }}
                                                >
                                                  {color.hex}
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <SliderPicker
                                            color={color}
                                            triangle="hide"
                                            onChangeComplete={setColor}
                                          />

                                          {errors.color && (
                                            <div className="text-danger fs-12">
                                              {errors.color}
                                            </div>
                                          )}
                                        </div>
                                      </div>

                                      <div className="form-row">
                                        <div className="form-group col-md-6">
                                          <label>
                                            Contact Number{" "}
                                            <small>(Help &amp; Support)</small>
                                          </label>
                                          {/* <input
                                            type="text"
                                            value={helpContact}
                                            onChange={(e) =>
                                              setHelpContact(e.target.value)
                                            }
                                            placeholder="Contact Number"
                                            className="form-control"
                                          /> */}

                                          <PhoneInput
                                            placeholder="Enter phone number"
                                            value={helpContact}
                                            defaultCountry="IN"
                                            onChange={setHelpContact}
                                          />

                                          {errors.contact && (
                                            <div className="text-danger fs-12">
                                              {errors.contact}
                                            </div>
                                          )}
                                        </div>
                                        <div className="form-group col-md-6">
                                          <label>
                                            Email Address{" "}
                                            <small>(Help &amp; Support)</small>
                                          </label>
                                          <input
                                            type="email"
                                            value={helpEmail}
                                            onChange={(e) =>
                                              setHelpEmail(e.target.value)
                                            }
                                            placeholder="Email Address"
                                            className="form-control"
                                          />
                                          {errors.email && (
                                            <div className="text-danger fs-12">
                                              {errors.email}
                                            </div>
                                          )}
                                        </div>
                                      </div>

                                      <div className="form-row">
                                        <div className="form-group col-md-6">
                                          <label>
                                            Main Site Url{" "}
                                            <small>(Buy Product Link)</small>
                                          </label>
                                          <input
                                            type="text"
                                            value={siteLink}
                                            onChange={(e) =>
                                              setSiteLink(e.target.value)
                                            }
                                            placeholder="e.g https://"
                                            className="form-control"
                                          />
                                          {errors.siteLink && (
                                            <div className="text-danger fs-12">
                                              {errors.siteLink}
                                            </div>
                                          )}
                                        </div>

                                        <div className="form-group col-md-6">
                                          <label>
                                            Video Demo Url{" "}
                                            <small>(YouTube Video Link)</small>
                                          </label>
                                          <input
                                            type="text"
                                            value={demoVideoLink}
                                            onChange={(e) =>
                                              setDemoVideoLink(e.target.value)
                                            }
                                            placeholder="e.g https://"
                                            className="form-control"
                                          />
                                          {errors.demoVideoLink && (
                                            <div className="text-danger fs-12">
                                              {errors.demoVideoLink}
                                            </div>
                                          )}
                                        </div>
                                      </div>

                                      <div className="form-row">
                                        <div className="form-group col-md-3">
                                          <label>Show Ads?</label>

                                          <select
                                            className="form-control"
                                            value={showAd ? "yes" : "no"}
                                            onChange={(e) =>
                                              setShowAd(e.target.value == "yes")
                                            }
                                            defaultValue="yes"
                                          >
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                          </select>
                                        </div>

                                        <div className="form-group col-md-9">
                                          {/* <label>Ads Banner Url</label>
                                          <input
                                            type="text"
                                            value={adBannerLink}
                                            onChange={(e) =>
                                              setBannerAdLink(e.target.value)
                                            }
                                            placeholder="e.g https://"
                                            className="form-control"
                                          />
                                          {errors.adBannerLink && (
                                            <div className="text-danger fs-12">
                                              {errors.adBannerLink}
                                            </div>
                                          )} */}
                                        </div>

                                        <div className="col-md-3">
                                          <div className="text-center">
                                            <div
                                              className="card"
                                              style={{
                                                background: "#F5F5F5",
                                              }}
                                            >
                                              <img
                                                onClick={upload}
                                                className="img img-fluid my-4"
                                                style={{ cursor: "pointer" }}
                                                src={
                                                  adBannerLink != ""
                                                    ? adBannerLink
                                                    : noIamage
                                                }
                                              ></img>
                                              <input
                                                id="selectFile"
                                                type="file"
                                                hidden
                                                onChange={fileChangedHandler}
                                                onClick={fileClickHandler}
                                              />

                                              <div className="card-footer">
                                                <Button
                                                  onClick={upload}
                                                  className="btn btn-sm btn-success"
                                                >
                                                  Upload
                                                </Button>


                                                {errors.adBannerLink && (
                              <div className="text-danger fs-12 mt-2">
                                {errors.adBannerLink}
                              </div>
                            )}


                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-md-9">
                                          <div className="form-group">
                                            <label>Ads Action Url</label>
                                            <input
                                              type="text"
                                              value={adLink}
                                              onChange={(e) =>
                                                setAdLink(e.target.value)
                                              }
                                              placeholder="e.g https://"
                                              className="form-control"
                                            />
                                            {errors.adLink && (
                                              <div className="text-danger fs-12">
                                                {errors.adLink}
                                              </div>
                                            )}
                                          </div>

                                          <div className="form-row">
                                            <div className="form-group col-md-3">
                                              <label>Version</label>
                                              <input
                                                type="number"
                                                value={version}
                                                onChange={(e) =>
                                                  setVersion(e.target.value)
                                                }
                                                placeholder="Software Version"
                                                className="form-control"
                                                readOnly
                                                disabled
                                              />
                                            </div>
                                            <div className="form-group col-md-9">
                                              <label>
                                                Software Download Link
                                              </label>
                                              <InputGroup className="mb-3">
                                                {/* <FormControl
                                                  placeholder="No download link found"
                                                  aria-label="Software Download Link"
                                                  aria-describedby="basic-apply"
                                                  value={updateUrl}
                                                  readOnly
                                                  disabled
                                                /> */}
                                                <a
                                                  className="btn btn-primary"
                                                  variant="primary"
                                                  href={
                                                    updateUrl ? updateUrl : "#"
                                                  }
                                                  target={
                                                    updateUrl ? "_blank" : ""
                                                  }
                                                  style={{
                                                    borderRadius:
                                                      "0px 5px 5px 0px",
                                                  }}
                                                >
                                                  Download Now
                                                </a>
                                              </InputGroup>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="text-center my-4">
                                          <button
                                            className="btn btn-lg btn-primary"
                                            type="submit"
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <div className="mt-4">
                                      <h4>No Rebranding Setting Available</h4>
                                      <p>
                                        For rebranding please contact on us on{" "}
                                        <b>
                                          <a
                                            className="text-primary"
                                            href={
                                              "tel:" +
                                              process.env.REACT_APP_PHONE_NUMBER
                                            }
                                          >
                                            {process.env.REACT_APP_PHONE_NUMBER}
                                          </a>
                                        </b>{" "}
                                        or email us on{" "}
                                        <b>
                                          <a
                                            className="text-primary"
                                            href={
                                              "mailto:" +
                                              process.env.REACT_APP_EMAIL_ID
                                            }
                                          >
                                            {process.env.REACT_APP_EMAIL_ID}.
                                          </a>
                                        </b>
                                        <br />
                                        <br />
                                        OR
                                        <br />
                                        <br />
                                        <div className="form-group mb-3">
                                          <ul class="list-group mb-3">
                                            <li class="list-group-item d-flex justify-content-between lh-condensed">
                                              <div>
                                                <h6 class="my-0">
                                                  Your Wallet Balance
                                                </h6>
                                              </div>
                                              <span class="text-muted">
                                                {profile.wallet ?? 0}
                                              </span>
                                            </li>

                                            <li class="list-group-item d-flex justify-content-between">
                                              <span>Total Point</span>
                                              <strong>
                                                {appData.rebrandPrice ?? 1499}
                                              </strong>
                                            </li>
                                          </ul>
                                        </div>
                                        {(appData.rebrandPrice ?? 1499) >
                                        (profile.wallet ?? 0) ? (
                                          <Alert
                                            className="mt-4"
                                            variant={"warning"}
                                          >
                                            Insufficient balance for transfer
                                            keys!
                                          </Alert>
                                        ) : (
                                          <></>
                                        )}
                                        <Button
                                          className="btn btn-sm btn-primary"
                                          onClick={(e) => onActiveRebranding()}
                                        >
                                          Buy Rebranding
                                        </Button>
                                      </p>
                                    </div>
                                  )
                                ) : (
                                  <></>
                                )}
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default AgentDetails;
