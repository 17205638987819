import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  Dropdown,
  Button,
  Modal,
  Table,
  Form,
  Badge,
  Card,
  ListGroup,
} from "react-bootstrap";

import PageTitle from "../../layouts/PageTitle";

import { SRLWrapper } from "simple-react-lightbox";

import { useDispatch, useSelector } from "react-redux";

import axiosInstance from "../../../services/AxiosInstance";

import swal from "sweetalert";

import FormData from "form-data";
import { getAllProducts } from "../../../store/actions/licActions";

const Products = () => {
  const products = useSelector((state) => state.lic.products);
  const [selectedProduct, setSelectedProduct] = useState("select");

  const [activeToggle, setActiveToggle] = useState("posts");
  const [sendMessage, setSendMessage] = useState(false);
  const [replay, setReplay] = useState(false);

  const [productData, setProductData] = useState([]);
  const [test, setTest] = useState(true);

  //product setting
  const [productSettingModal, setProductSettingModal] = useState(false);
  const [activeProduct, setActiveProduct] = useState(null);
  const [productName, setProductName] = useState("");
  const [productEnable, setProductEnable] = useState(false);

  const dispatch = useDispatch();

  let errorsObj = {
    name: "",
    description: "",
    androidUpdate: "",
    iosUpdate: "",
    androidVersion: "",
    iosVersion: "",
    appStoreUrl: "",
    playStoreUrl: "",
    newsUpdate: "",
    frontImage: "",
    frontLink: "",
    backImage: "",
    backLink: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [androidUpdate, setAndroidUpdate] = useState(true);
  const [androidVersion, setAndroidVersion] = useState("");
  const [playStoreUrl, setPlayStoreUrl] = useState("");
  const [newsUpdate, setNewsUpdate] = useState("");
  const [frontImage, setFrontImage] = useState("");
  const [frontLink, setFrontLink] = useState("");
  const [backImage, setBackImage] = useState("");
  const [backLink, setBackLink] = useState("");

  //const [profile, setProfile] = useState({});

  const options = {
    settings: {
      overlayColor: "#000000",
    },
  };

  useEffect(() => {}, [products]);

  useEffect(() => {
    //loadDetails();
    dispatch(getAllProducts());
  }, [dispatch]);

  return (
    <>
      <Fragment>
        <div className="row">
          <div className="col-xl-12">
            <div className="row">
              {products
                .filter((x) => x.enable)
                .map((product, i) => {
                  return (
                    <div className="col-md-4" id={product.id}>
                      <Card className="shadow" style={{ boxShadow: "0 10px 15px rgba(0, 0, 0, 0.5);",borderRadius:"15px"}}>
                        <Card.Img
                        className="img-fluid px-5"
                          //style={{ maxHeight: "250px" }}
                          variant="top"
                          src={product.image}
                        />
                        <Card.Body>
                          <Card.Title>{product.name}</Card.Title>
                          <Card.Text></Card.Text>
                          <ListGroup variant="flush">
                            {product.variations.map((pv) => {
                              return (
                                <ListGroup.Item key={pv.id}>
                                  <div class="d-flex justify-content-between">
                                    {pv.title}{" "}
                                    <Button variant="success" size="sm">
                                      {pv.price} Point
                                    </Button>
                                  </div>
                                </ListGroup.Item>
                              );
                            })}
                          </ListGroup>
                        </Card.Body>
                      </Card>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default Products;
