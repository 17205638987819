import React, { useState,useEffect} from 'react';
import { Link} from 'react-router-dom';
import swal from "sweetalert";
import {nanoid} from 'nanoid';
import xlsx from 'xlsx';
import Moment from 'moment';
import { format } from "date-fns";

import { useDispatch,useSelector } from "react-redux";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { CSVLink } from "react-csv";

import {
	Row,
	Col,
	Card,
	Table,
	Badge,
	Dropdown,
	Modal,
	ProgressBar,
   Button,
   Spinner
 } from "react-bootstrap";

 /// imge
import avatar1 from "../../../images/avatar/1.jpg";
import avatar2 from "../../../images/avatar/2.jpg";
import avatar3 from "../../../images/avatar/3.jpg";

import {
    inviteMemberAction,
    bulkInviteMemberAction,
    cancelInvitationAction,
	getInvitationsAction,
   resetAllAction
} from '../../../store/actions/MemberActions';

import {
   getProfile,
} from '../../../store/actions/AuthActions';

import axiosInstance from '../../../services/AxiosInstance';


import {
   EmailShareButton,
   FacebookShareButton,
   LinkedinShareButton,
   TelegramShareButton,
   TwitterShareButton,
   WhatsappShareButton,

   EmailIcon,
   FacebookIcon,
   LinkedinIcon,
   TelegramIcon,
   TwitterIcon,
   WhatsappIcon,
 } from "react-share";

const MemberPage = ({ props, history }) => {
	
	const dispatch = useDispatch();

    const [transferModal, setTransferModal] = useState(false);
    const [inviteModal, setInviteModal] = useState(false);
    const [checkAllMember, setCheckAllMember] = useState(false);

    const [copied, setCopied] = useState(false);
    const [loading, setLoading] = useState(false);

	const members = useSelector((state) => state.members.invitations);

   const [search, setSearch] = useState("");
   const [searchTimeout, setSearchTimeout] = useState(null);
   const [xlsData, setXlsData] = useState([]);

   const [addMemberModal, setAddMemberModal] = useState(false);
   const [phone, setPhone] = useState("");
   const [email, setEmail] = useState("");
   const [name, setName] = useState("");
   let errorsObj = { name: '',email:"",phone:""};
   const [errors, setErrors] = useState(errorsObj);

   const [editMemberModal, setEditMemberModal] = useState(false);

   //const [activeMember, setActiveMember] = useState(null);

useEffect(() => {
  if (searchTimeout) {
    clearTimeout(searchTimeout);
  }

  setSearchTimeout(
    setTimeout(() => {
      dispatch(getInvitationsAction({"search":search}));
    }, 1000)
  );

  return () => clearTimeout(searchTimeout);
}, [search]);


   useEffect(() => {
		if(members){
         setLoading(false);
      }
	}, [members]);

   

   const profile = useSelector((state) => state.auth.profile);

   //edit profile
   const [activeMember, setActiveMember] = useState("");

   //const shareTitle = "pvc2print invite";
   //const shareBody = "pvc2print";
  
	//const { invitations} = getInvitations;
	useEffect(() => {
      dispatch(getInvitationsAction({}));
      dispatch(getProfile());
	  }, [dispatch]);

  // transfer counter 
    const [transferCounterFormData, setTransferCounterFormData ] = useState({
      phone:"",
      counter:''
  }); 
  
  // update member function
  const handleTransferCounterFormChange = (event) => {
      event.preventDefault();    
      const fieldName = event.target.getAttribute('name');
      const fieldValue = event.target.value;
      const newFormData = {...transferCounterFormData};
      newFormData[fieldName] = fieldValue;
      setTransferCounterFormData(newFormData);
  };

       // Update Profile Submit data
       const handleTransferCounterFormSubmit = async (event)=> {
         event.preventDefault();
         
         var error = false;
         var errorMsg = '';
 
       if(transferCounterFormData.phone === ""){
             error = true;
          errorMsg = 'Phone Number is Required';
       }else if(transferCounterFormData.phone.length != 10 ){
          error = true;
          errorMsg = 'Phone number is not valid';
       }else if(transferCounterFormData.counter === ""){
          error = true;
          errorMsg = 'Counter is Required';
       }
 
         if(!error){
             
         
         try {
			
            const { data } = await axiosInstance.post(
               "master-reseller/transfer-counter",
               { counter:transferCounterFormData.counter,phone:"+91"+transferCounterFormData.phone }
            );
   

        console.log("master-reseller/transfer-counter - "+JSON.stringify(data));
   
        if(data.status === false){
           //toast.error(data.message);
         swal('Transfer Counter', data.message,"error");

        }else{
           //toast.success(data.message);
         swal('Transfer Counter', data.message, "success");

         transferCounterFormData.phone = '';
         transferCounterFormData.counter = "";     
       
           setTimeout(() => {
              setTransferModal(false);
              dispatch(getInvitationsAction());

            }, 500)

         
          }
         
         }catch (error) {
			
         }


                   
             
         }else{
          swal('Oops', errorMsg, "error");
       }
     };

   // On Edit Profile
   const onSendCounter= (member) => {
      const newFormData = {phone:member.phone.substring(member.phone.length - 10),counter:0};
      setTransferCounterFormData(newFormData);
      setActiveMember(member.id);
      setTransferModal(true);
   }

   
   const onSubAgent= async (member) => {

      const { data } = await axiosInstance.post(
         "master-reseller/make-sub-agent",
         {
            user_id:member.id
         }
      );

      if(data.status){
         swal('Make Sub Agent', data.message, "success");
         dispatch(getInvitationsAction({}));
      }else{
         swal('Make Sub Agent', data.message, "error");
      }

   }


   function shareTitle(){
      return "pcv2print invite";
   }

   function shareBody(){
      //return "pcv2print";
      return "Hi! I am sending you invite link of pvc2print App. Sign up on the pvc2print App using my link to claim the free credits.\n\npvc2print App provide PVC card printing functionality using 3 steps only. One App for multiple Card Types. pvc2print support all card printer.\n\nHow pvc2print work?\n1.0 Upload PDF\n2.0 Select your printer type\n3.0 Preview & Print/Share\n\n";
   }

   function shareBody2(){
      //return "pcv2print";
      return "Hi! I am sending you invite link of pvc2print App. Sign up on the pvc2print App using my link to claim the free credits.\n\nHow pvc2print work?\n1.0 Upload PDF\n2.0 Select your printer type\n3.0 Preview & Print/Share\n\n";
   }

   function onEditMember(member){
      setActiveMember(member.id);

      setPhone(member.phone.substring(member.phone.length - 10));
      setEmail(member.email);
      setName(member.name);

      setEditMemberModal(true);
   }

   const onSaveMember = async (event)=> {
      event.preventDefault();   
      let error = false;
      var errorObj = { };

      if(name === ""){
         error = true;
         errorObj.name = 'Name is Required';
      }

      if(email === ""){
         error = true;
         errorObj.email = 'Email is Required';
      }

      setErrors(errorObj);
      if(!error){
      
         try {
			
            const { data } = await axiosInstance.post(
               "master-reseller/update-member",
               { name:name,email:email,user_id:activeMember}
            );
   
        if(data.status === false){
           
            swal('Update Member', data.message,"error");

        }else{

            setEditMemberModal(false);
            swal('Update Member', data.message, "success");

            setPhone("");
            setEmail("");
            setName("");
            
            setTimeout(() => {
               dispatch(getInvitationsAction());
               }, 500)

         }
         
         }catch (error) {
			
         }


      }
   }



   const onAddMember = async (event)=> {
      event.preventDefault();    
      let error = false;
      var errorObj = { };

      if(name === ""){
         error = true;
         errorObj.name = 'Name is Required';
      }

      if(phone === ""){
          error = true;
          errorObj.phone = 'Phone Number is Required';
      }else if(phone.length != 10 ){
       error = true;
       errorObj.phone = 'Phone number is not valid';
      }
      
      if(email === ""){
         error = true;
         errorObj.email = 'Email is Required';
      }

      setErrors(errorObj);
      if(!error){
      
         try {
			
            const { data } = await axiosInstance.post(
               "master-reseller/add-member",
               { name:name,phone:"+91"+phone,email:email}
            );
   
        if(data.status === false){
           //toast.error(data.message);
         swal('Add Member', data.message,"error");

        }else{
         setAddMemberModal(false);
           //toast.success(data.message);
         swal('Add Member', data.message, "success");

         setPhone("");
         setEmail("");
         setName("");
          
           setTimeout(() => {
              dispatch(getInvitationsAction());
            }, 500)
         }
         
         }catch (error) {
			
         }


      }

   }

   
   const initXlsData = async () => {

      var dummy = [];
      members.map((data,i)=>{

         dummy.push({
            sr_no:i+1,
            name:data.name,
            phone:data.phone,
            email:data.email,
            counter:data.counter,
            created_at:format(new Date(data.createdAt), "dd/MM/yyyy H:mma"),
         });
      });


      setXlsData(dummy);
   }
   

    
    return(
        <>
                      

			
            
                      <Modal className="modal fade"  show={inviteModal} onHide={setInviteModal} >
					<div className="" role="document">
						<div className="">
							<form >
								<div className="modal-header">
									<h4 className="modal-title fs-20">Invite Member</h4>
									<button type="button" className="close" onClick={()=> setInviteModal(false)}><span>×</span></button>
								</div>
								<div className="modal-body">
									<i className="flaticon-cancel-12 close"></i>
									<div className="add-contact-box">
										<div className="add-contact-content">

                                 <p>Your refer code:</p>
                                 <h3>{profile ? profile.referCode :"----"}</h3>

                                

                                 <p>{profile ? profile.referLink : ""}</p>
                                 <CopyToClipboard text={profile ? profile.referLink : ""}
                                    onCopy={() => {
                                       setCopied(true);
                                       setTimeout(() => {
                                          setCopied(false);
                                        }, 1500);
                                    
                                    }}>
                                    <div className='d-flex'><button className="btn btn-sm btn-light mr-2">Copy link</button>{copied?(<span className='mt-2' style={{color: 'red'}}>Copied.</span>):""}</div>
                                 </CopyToClipboard>

                                 <div className='row mt-4'>

                                 <div className='col-md-2'>
                                    <EmailShareButton url={profile ? profile.referLink : "https://pvc2print.page.link/2022"} 
                                    body={shareBody()} 
                                    subject={shareTitle()}>
                                    
                                 <EmailIcon size={32} round />

                                    </EmailShareButton></div>

                                 <div className='col-md-2'> <FacebookShareButton
                                 url={profile ? profile.referLink : "https://pvc2print.page.link/2022"}
                                 quote={shareBody()} hashtag='#pvc2print'>

                                 <FacebookIcon size={32} round />


                                 </FacebookShareButton>
                                 </div>
                                 <div className='col-md-2'><LinkedinShareButton url={profile ? profile.referLink : "https://pvc2print.page.link/2022"} title={shareTitle()} summary={shareBody()}>

                                 <LinkedinIcon size={32} round />
                                 </LinkedinShareButton>
                                </div>
                                 <div className='col-md-2'><TelegramShareButton url={profile ? profile.referLink : "https://pvc2print.page.link/2022"} title={shareBody()}>

                                 <TelegramIcon size={32} round />

                                 </TelegramShareButton>
                                 </div>
                                 <div className='col-md-2'><TwitterShareButton url={profile ? profile.referLink : "https://pvc2print.page.link/2022"} title={shareBody2()}>

                                 <TwitterIcon size={32} round />
                                 </TwitterShareButton>
                                 </div>
                                 <div className='col-md-2'><WhatsappShareButton url={profile ? profile.referLink : "https://pvc2print.page.link/2022"} title={shareBody()} >

                                 <WhatsappIcon size={32} round />

                                 </WhatsappShareButton>
			</div>
                                 </div>
                                 
                                 
										</div>
									</div>
								</div>
								
							</form>
                            
						</div>
					</div>
				</Modal>



            
            <Modal className="modal fade"  show={transferModal} onHide={setTransferModal} >
					<div className="" role="document">
						<div className="">
							<form >
								<div className="modal-header">
									<h4 className="modal-title fs-20">Transfer Counter</h4>
									<button type="button" className="close" onClick={()=> setTransferModal(false)}><span>×</span></button>
								</div>
								<div className="modal-body">
									<i className="flaticon-cancel-12 close"></i>
									<div className="add-contact-box">
										<div className="add-contact-content">


										<div className="form-group mb-3">
												<label className="text-black font-w500">Phone Number</label>
												<div className="contact-name">
													<input type="text" value={transferCounterFormData.phone}  className="form-control"  autocomplete="off"
														name="phone" required="required"
                                                        onChange={handleTransferCounterFormChange}
														placeholder="10 Digit Phone Number"
													/>
													<span className="validation-text"></span>
												</div>
											</div>

											
											 <div className="form-group mb-3">
												<label className="text-black font-w500">Counter</label>
												<div className="contact-name">
													<input type="number" value={transferCounterFormData.counter}  className="form-control"  autocomplete="off"
														name="counter" onkeypress="return event.charCode>=48 && event.charCode<=57" required="required"
                                                        onChange={handleTransferCounterFormChange}
														placeholder="Counter"
													/>
													<span className="validation-text"></span>
												</div>
											</div>
                                          
			
										</div>
									</div>
								</div>
								<div className="modal-footer">
                                     
                                    <button type="button" onClick={()=> setTransferModal(false)} className="btn btn-danger"> <i className="flaticon-delete-1"></i> Discard</button>   
                                    <button type="submit" className="btn btn-primary" onClick={handleTransferCounterFormSubmit}>Transfer</button>    
								</div>
							</form>
                            
						</div>
					</div>
				</Modal>

            
            
            <Modal className="modal fade"  show={addMemberModal} onHide={setAddMemberModal} >
					<div className="" role="document">
						<div className="">
							<form onSubmit={onAddMember}>
								<div className="modal-header">
									<h4 className="modal-title fs-20">Add Member</h4>
									<button type="button" className="close" onClick={()=> setAddMemberModal(false)}><span>×</span></button>
								</div>
								<div className="modal-body">
									<i className="flaticon-cancel-12 close"></i>
									<div className="add-contact-box">
										<div className="add-contact-content">

                              <div className="form-group mb-3">
												<label className="text-black font-w500">Name</label>
												<div className="contact-name">
													<input type="text" value={name}  className="form-control"  autocomplete="off"
														name="name" required="required"
                                          onChange={(e) =>
                                             setName(e.target.value)
                                          }
														placeholder="Name"
													/>
                                       {errors.name && <div className="text-danger fs-12">{errors.name}</div>}
                                             
													<span className="validation-text"></span>
												</div>
											</div>

										<div className="form-group mb-3">
												<label className="text-black font-w500">Phone Number</label>
												<div className="contact-name">
													<input type="text" value={phone}  className="form-control"  autocomplete="off"
														name="name" required="required"
                                          onChange={(e) =>
                                             setPhone(e.target.value)
                                          }
														placeholder="10 Digit Phone Number"
													/>
                                       {errors.phone && <div className="text-danger fs-12">{errors.phone}</div>}
                                             
													<span className="validation-text"></span>
												</div>
											</div>

											
											<div className="form-group mb-3">
												<label className="text-black font-w500">Email</label>
												<div className="contact-name">
													<input type="email" value={email}  className="form-control"  autocomplete="off"
														name="name" required="required"
                                          onChange={(e) =>
                                             setEmail(e.target.value)
                                          }
														placeholder="Email"
													/>
                                       {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
                                             
													<span className="validation-text"></span>
												</div>
											</div>
                                          
			
										</div>
									</div>
								</div>
								<div className="modal-footer">
                                     
                                    <button type="button" onClick={()=> setAddMemberModal(false)} className="btn btn-danger"> <i className="flaticon-delete-1"></i> Discard</button>   
                                    <button type="submit" className="btn btn-primary">Add</button>    
								</div>
							</form>
                            
						</div>
					</div>
				</Modal>

 
            <Modal className="modal fade" show={editMemberModal} onHide={setEditMemberModal} >
					<div className="" role="document">
						<div className="">
							<form onSubmit={onSaveMember}>
								<div className="modal-header">
									<h4 className="modal-title fs-20">Edit Member</h4>
									<button type="button" className="close" onClick={()=> setEditMemberModal(false)}><span>×</span></button>
								</div>
								<div className="modal-body">
									<i className="flaticon-cancel-12 close"></i>
									<div className="add-contact-box">
										<div className="add-contact-content">

                              <div className="form-group mb-3">
												<label className="text-black font-w500">Name</label>
												<div className="contact-name">
													<input type="text" value={name}  className="form-control"  autocomplete="off"
														name="name" required="required"
                                          onChange={(e) =>
                                             setName(e.target.value)
                                          }
														placeholder="Name"
													/>
                                       {errors.name && <div className="text-danger fs-12">{errors.name}</div>}
                                             
													<span className="validation-text"></span>
												</div>
											</div>

										<div className="form-group mb-3">
												<label className="text-black font-w500">Phone Number</label>
												<div className="contact-name">
													<input type="text" value={phone}  className="form-control"  autocomplete="off"
														name="name" required="required" disabled
                                          onChange={(e) =>
                                             setPhone(e.target.value)
                                          }
														placeholder="10 Digit Phone Number"
													/>
                                       {errors.phone && <div className="text-danger fs-12">{errors.phone}</div>}
                                             
													<span className="validation-text"></span>
												</div>
											</div>

											
											<div className="form-group mb-3">
												<label className="text-black font-w500">Email</label>
												<div className="contact-name">
													<input type="email" value={email}  className="form-control"  autocomplete="off"
														name="name" required="required"
                                          onChange={(e) =>
                                             setEmail(e.target.value)
                                          }
														placeholder="Email"
													/>
                                       {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
                                             
													<span className="validation-text"></span>
												</div>
											</div>
                                          
			
										</div>
									</div>
								</div>
								<div className="modal-footer">
                                     
                                    <button type="button" onClick={()=> setEditMemberModal(false)} className="btn btn-danger"> <i className="flaticon-delete-1"></i> Discard</button>   
                                    <button type="submit" className="btn btn-primary">Save</button>    
								</div>
							</form>
                            
						</div>
					</div>
				</Modal>


			<Row>

			<Col lg={12}>
               <Card>
                  <Card.Header>
                     
                        
                     
                     <Card.Title className="mr-auto p-2">MEMBERS</Card.Title>
                     
                        
                     <div className="input-group search-area d-lg-inline-flex d-none mr-2">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (searchTimeout) {
                      clearTimeout(searchTimeout);
                    }
                    dispatch(getInvitationsAction({"search":search}));
                  }}
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </form>
                <div className="input-group-append">
                  <span className="input-group-text">
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"
                        fill="#A4A4A4"
                      />
                    </svg>
                  </span>
                </div>
              </div>

                     
                        <div class="d-flex justify-content-end">

                       
                        <Link className="btn btn-light font-w600 mr-2" onClick={()=> setAddMemberModal(true)}>Add Member</Link>
				    
                        

				<Link className="btn btn-light font-w600 mr-2" onClick={()=> setInviteModal(true)}>Invite Member</Link>
				 
             
            <CSVLink
  data={xlsData}
  filename={"members.csv"}
  className="btn btn-primary"
  target="_blank"
  asyncOnClick={true}
  onClick={async (event, done) => {
   console.log("You click the link");
   await initXlsData();
   done(); // 👍🏻 You are stopping the handling of component
 }}

>
  Export
</CSVLink>
				 
           
           </div>

                        
                        
                  </Card.Header>
                  <Card.Body>

                  {loading ? <div className="text-center mt-4"><Spinner animation="border" variant="primary" /></div>:(
       
       members.length == 0 ? <p>No Member Found</p>:
                     <Table responsive>
                        <thead>
                           <tr>
                             
                              <th>
                                 <strong>SR NO.</strong>
                              </th>
                              <th>
                                 <strong>Name</strong>
                              </th>
                              <th>
                                 <strong>Phone</strong>
                              </th>
                              <th>
                                 <strong>Email</strong>
                              </th>

                              <th>
                                 <strong>Counters</strong>
                              </th>
                              <th>
                                 <strong>Joined At</strong>
                              </th>

                              <th>
                                 <strong>Action</strong>
                              </th>
                            
                           </tr>
                        </thead>
                        <tbody>

						{
          members.map((invitation,i) => (

                           <tr key={i}>
                             
                              <td>
                                 <strong>{i+1}</strong>
                              </td>
                              <td>
                                 <div className="d-flex align-items-center">
                                  {""}
                                    <span className="w-space-no">
                                       {invitation.name}
                                    </span>
                                 </div>
                              </td>
                              <td>{invitation.phone} </td>
                              <td>{invitation.email} </td>
                              <td>{invitation.counter} </td>
                             
                              <td>{ format(new Date(invitation.createdAt), "dd/MM/yyyy H:mma")}</td>
                             
                              <td>
                                


         <Dropdown>
                                             <Dropdown.Toggle
                                                variant=""
                                                className="table-dropdown icon-false"
                                             >
                                                <svg
                                                   width="24px"
                                                   height="24px"
                                                   viewBox="0 0 24 24"
                                                   version="1.1"
                                                >
                                                   <g
                                                      stroke="none"
                                                      strokeWidth="1"
                                                      fill="none"
                                                      fillRule="evenodd"
                                                   >
                                                      <rect
                                                         x="0"
                                                         y="0"
                                                         width="24"
                                                         height="24"
                                                      ></rect>
                                                      <circle
                                                         fill="#000000"
                                                         cx="5"
                                                         cy="12"
                                                         r="2"
                                                      ></circle>
                                                      <circle
                                                         fill="#000000"
                                                         cx="12"
                                                         cy="12"
                                                         r="2"
                                                      ></circle>
                                                      <circle
                                                         fill="#000000"
                                                         cx="19"
                                                         cy="12"
                                                         r="2"
                                                      ></circle>
                                                   </g>
                                                </svg>
                                             </Dropdown.Toggle>
                                             <Dropdown.Menu>
                                                <Dropdown.Item as="button" onClick={(e)=>onSendCounter(invitation)}>
                                                   Transfer Counter
                                                </Dropdown.Item>
                                                <Dropdown.Item as="button" onClick={(e)=>onSubAgent(invitation)}>
                                                   Make Sub Agent
                                                </Dropdown.Item>

                                                <Dropdown.Item as="button" onClick={(e)=>onEditMember(invitation)}>
                                                   Edit Profile
                                                </Dropdown.Item>
                                              
                                               {/*  <div className="dropdown-divider"></div>
                                                <Dropdown.Item
                                                   href="#"
                                                   className="text-danger"
                                                >
                                                   Delete
                                                </Dropdown.Item> */}
                                             </Dropdown.Menu>
                                          </Dropdown>

                      

                                    
                                
                              </td>
                           </tr>))
						   
						}

                         
                        </tbody>
                     </Table>)}
                  </Card.Body>
               </Card>
            </Col>

			</Row>

            
        </>
    );     
}

export default MemberPage;