import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import menu03 from "../../../images/menus/3.png";
import menu02 from "../../../images/menus/2.png";
import menu01 from "../../../images/menus/1.png";

import testimonial from "../../../images/testimonial/1.jpg";
import testimonial2 from "../../../images/testimonial/2.jpg";
import testimonial3 from "../../../images/testimonial/3.jpg";

import {
  Spinner,
  Table,
  Pagination,
  Col,
  Row,
  Card,
  Badge,
  Dropdown,
  Tab,
  Nav,
  ListGroup
} from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";

import axiosInstance from "../../../services/AxiosInstance";

import { getProfile } from "../../../store/actions/AuthActions";

import ReactApexChart from "react-apexcharts";

// import Ext from "../../layouts/Ext";

const Home = () => {
  const dispatch = useDispatch();

  const [filter, setFilter] = useState("daily");
  const [type, setType] = useState("all");

  const [loadingData, setLoadingData] = useState(true);

  const [totalActiveUser, setTotalActiveUser] = useState(0);
  const [totalActivation, setTotalActivation] = useState(0);

  const [options, setOptions] = useState({});
	const [series, setSeries] = useState({});

  const [statistics, setStatistics] = useState(null);
  const profile = useSelector((state) => state.auth.profile);

  useEffect(() => {
    dispatch(getProfile());
    loadStatistics();
    loadCounterStatistics(filter,type);
  }, [dispatch]);

  async function loadStatistics() {
    try {
      const { data } = await axiosInstance.get("master-reseller/statistics");

      console.log("statistics data - " + JSON.stringify(data));

      if (data.status === false) {
      } else {
        setStatistics(data.statistics);
      }
    } catch (error) {}
  }

  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <div className="owl-next" onClick={onClick} style={{ zIndex: 99 }}>
        <i className="fa fa-caret-right" />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div
        className="owl-prev disabled"
        onClick={onClick}
        style={{ zIndex: 99 }}
      >
        <i className="fa fa-caret-left" />
      </div>
    );
  }

  async function onChangeFilter(filter) {
    setFilter(filter);
    await loadCounterStatistics(filter,type);
  }

  async function onChangeType(type) {
  
   setType(filter);
   await loadCounterStatistics(filter,type);
 }

  async function loadCounterStatistics(filter,type) {
   setLoadingData(true);
    try {
      const { data } = await axiosInstance.post("master-reseller/lic-statistics", {
        type: type,
        filter: filter,
      });

      console.log("filter data - " + JSON.stringify(data));

      if (data.status) {
        //setTodaysTaps(data.todayResult);

        var tc = 0;
        var active = 0;
        data.result.map((e) => {
          tc = tc + e.used;
          active = active + e.active;
        });

        setTotalActiveUser(tc);
        setTotalActivation(active)

        setSeries([
          {
            name: "Users",
            data: data.result.map((e) => e.used),
          },
          {
            name: "Activation",
            data: data.result.map((e) => e.active),
          },
        ]);

        setOptions({
          chart: {
            height: 350,
            type: "line",
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },

          stroke: {
            width: [4, 4],
            colors: ["#6cc51d"],
            curve: "straight",
          },
          legend: {
            show: false,
          },
          xaxis: {
            type: "text",
            categories: data.result.map((e) => e._id),
          },
          colors: ["#6cc51d", "#1EA7C5", "#FF9432"],

          markers: {
            size: [8, 8, 6],
            strokeWidth: [0, 0, 4],
            strokeColors: ["#6cc51d", "#1EA7C5", "#FF9432"],
            border: 0,
            colors: ["#6cc51d", "#1EA7C5", "#fff"],
            hover: {
              size: 10,
            },
          },
          yaxis: {
            title: {
              text: "",
            },
          },
        });

        
      } 
      
      setLoadingData(false);
      
    } catch (error) {
      setLoadingData(false);
    }
  }

  const settings = {
    focusOnSelect: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1599,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>

{/* <div class="alert alert-info" role="alert">
  <h4 class="alert-heading">New Prices!</h4>
  <p>Starting July 1st</p>
  <p>Our updated pricing is part of our commitment to improve Software, so have even more of what you like to sell. Thanks for being a part of Digi Bulk Marketing member.</p>
  <hr/>
  <p class="mb-0">Check our new pricing at out website <a target="_blank" href="https://digibulkmarketing.com/product/button-sender-reseller/?attribute_pa_pack=100-pc&attribute_pa_validity=1-year">https://digibulkmarketing.com</a>.</p>
</div> */}

      <div className="row">
        <div className="col-xl-3 col-md-6">
          <div className="card bordered-card">
            <Link to="resellers"><div className="card-body p-4">
              <div className="d-flex flex-wrap align-items-center text-head">
                <h2 className="fs-24 text-black font-w600 mb-0 mr-auto card-bold-title">
                  {statistics ? statistics.resellers : 0}
                </h2>
                <span className="circle-1 bg-primary mt-4" />
              </div>

              <span className="fs-14">Total Reseller</span>
            </div></Link>
          </div>
        </div>
      </div>


      {/* <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="card">
            <div className="card-header flex-wrap pb-0 border-0">
              <div className="mr-auto pr-3 mb-2">
                <h4 className="text-black fs-20">User Statistic</h4>
                <p className="fs-13 mb-2 mb-sm-0 text-black">
                  Total Active Users
                </p>
              </div>



              <div className="d-flex mr-3 mr-sm-5 mb-2">
                <div>
                  <h4 className="fs-18 text-black font-w600 mb-0">
                    {totalActiveUser}
                  </h4>
                  <span className="fs-12 text-black">Users</span>
                </div>
              </div>

              <div className="d-flex mr-3 mr-sm-5 mb-2">
                <div>
                  <h4 className="fs-18 text-black font-w600 mb-0">
                    {totalActivation}
                  </h4>
                  <span className="fs-12 text-black">Activation</span>
                </div>
              </div>
              

              <Dropdown className="dropdown mt-sm-0 mt-3 mb-0">
                <Dropdown.Toggle
                  variant=""
                  as="button"
                  className="btn rounded border border-light dropdown-toggle"
                >
                  {filter}
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-right">
                  <Dropdown.Item onClick={(e) => onChangeFilter("daily")}>
                    Daily
                  </Dropdown.Item>
                  <Dropdown.Item onClick={(e) => onChangeFilter("monthly")}>
                    Monthly
                  </Dropdown.Item>
                  <Dropdown.Item onClick={(e) => onChangeFilter("yearly")}>
                    Yearly
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="card-body pt-3">
              {loadingData ? (
                <div className="text-center my-2">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : (
                <div id="chart">
                  <ReactApexChart
                    options={options}
                    series={series}
                    type="line"
                    height={380}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div> */}


      {process.env.REACT_APP_SHOW_TERMS == "true"?<div class="row">
        <div class="col-md-12">
          <Card>
            <Card.Body>
              <Card.Title>Rules For Master Resellers</Card.Title>
            </Card.Body>
            <ListGroup variant="flush" className="reseller-rules">
              <ListGroup.Item>
                1. You have to sell minimum <span>5 licenses/month.</span>
              </ListGroup.Item>
              <ListGroup.Item>
                2. If minimum sales target will not met then your reseller account
                will permanently deactivated.
              </ListGroup.Item>
              <ListGroup.Item>
                3. If we found that, you take reseller panel for only own purpose
                not for selling, then we will deactivate all activate licenses &
                panel without any inform.
              </ListGroup.Item>
              <ListGroup.Item>
                4. Do not share your reseller keys price or any other information
                with anyone, if we found this then we will deactivate all
                activate licenses & panel without any inform.
              </ListGroup.Item>
              <ListGroup.Item>
                5. No full version keys will be deleted after created. if you
                created in panel then you have pay.
              </ListGroup.Item>
              <ListGroup.Item>6. <span>We dont have refund policy.</span></ListGroup.Item>
              <ListGroup.Item>
                7. Create full version keys with genuine Name, Email & Mobile
                Number details. If we found any fake data then we immediately
                delete the license.
              </ListGroup.Item>
              <ListGroup.Item>
                8. Create reseller account with genuine <span>Name, Email, Mobile Number</span> details. If we found any fake data then we will immediately
                account and license.
              </ListGroup.Item>
              <ListGroup.Item>
                9. We dont provide any full version keys to resellers for customer
                demo purpose. You have to buy keys for customer demo purpose
                also.
              </ListGroup.Item>
              <ListGroup.Item>
                10. Initially we help you for support for your customer, But you
                have to learn it from own then you have to give support to
                customer for future need.
              </ListGroup.Item>
              <ListGroup.Item>
                11. Reseller videos/images material for marketing purpose download
                link - <span>https://www.youtube.com/c/RADHESOLUTION</span>
              </ListGroup.Item>
              <ListGroup.Item>
                12. If want to create reseller under you then you have to maintain
                same above rules with him.
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </div>
      </div>:<></>}

    </>
  );
};

export default Home;
