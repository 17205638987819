import React, { useRef, useState, useEffect, Fragment } from "react";
import {
  Modal,
  Table,
  Pagination,
  Badge,
  Dropdown,
  Spinner,
  Button,
  Row,
  Col,
  Form,
  Alert
} from "react-bootstrap";

import PageTitle from "../../layouts/PageTitle";

import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";

import axiosInstance from "../../../services/AxiosInstance";
import swal from "sweetalert";

import { format } from "date-fns";

import { CSVLink } from "react-csv";

import moment from "moment";

import DTable from "../Table";

import Select from "react-select";

import {
  DateTimePicker,
  MuiPickersUtilsProvider,
  DatePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { getAllProducts } from "../../../store/actions/licActions";

import { profileAction } from "../../../store/actions/AuthActions";

//const Orders = () => {
const LicenseKeys = ({ match, props, history }) => {
  const dispatch = useDispatch();
  const [keys, setKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  const [xlsData, setXlsData] = useState([]);

  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);

  const [resellerModal, setResellerModal] = useState(false);

  const [activePage, setActivePage] = useState(0);

  const [filter, setFilter] = useState("all");
  const [product, setProduct] = useState("all");
  const products = useSelector((state) => state.lic.products);
  const [campaigns, setCampaigns] = useState([]);

  const [generateKeyModal, setGenerateKeyModal] = useState(false);
  const [newCampaign, setNewCampaign] = useState(false);
  const [campaignName, setCampaignName] = useState("");
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");

  const [note, setNote] = useState("");

  const [keyQty, setKeyQty] = useState(1);
  const [valid, setValid] = useState(365);
  const [errors, setErrors] = useState({});

  const [resellers, setResellers] = useState([]);
  const [activeKey, setActiveKey] = useState(null);
  const [selectedResellerId, setSelectedResellerId] = useState({
    value:"me",
    label:"My Self"
  });
  const [editKeyModal, setEditKeyModal] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [enable, setEnable] = useState(false);
  const [expireDate, setExpireDate] = useState(new Date());
  const [activeDate, setActiveDate] = useState(new Date());

  const [status, setStatus] = useState("0");

  const [transferLoading, setTransferLoading] = useState(false);

  const profile = useSelector((state) => state.auth.profile);

    //Transfer Keys
    const [transferModal, setTransferModal] = useState(false);
    const [variations, setvariations] = useState([]);
    const [selectedVariation, setSelectedVariation] = useState(null);
    const [enableTransfer, setEnableTransfer] = useState(false);
    const [totalPoint, setTotalPoint] = useState(0);
    const [keyCount, setKeyCount] = useState(1);
    const [price, setPrice] = useState(0);
    //const [activeMember, setActiveMember] = useState("");



  // Data Table Start
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = useState(10);
  const pageSizes = [10, 25, 50, 100];
  const [maxPage, setMaxPage] = useState(1);

  const handlePageChange = (value) => {
    setPage(value);
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setPage(0);
  };

  useEffect(() => {
    loadKeys();
  }, [page, pageSize]);

  // Data Table End

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    setSearchTimeout(
      setTimeout(() => {
        loadKeys();
      }, 1000)
    );

    return () => clearTimeout(searchTimeout);
  }, [search]);

  useEffect(() => {
    if(products){
      if (products.length > 0) {
        setSelectedProduct(products[0].id);
      }
    }
  }, [products]);

  useEffect(() => {
    //loadProducts();
    loadProfile();
    dispatch(getAllProducts());
    loadCampaigns();
    loadResellers();
   
  }, [dispatch]);

  async function loadProfile() {
    try {
      const { data } = await axiosInstance.get("auth/profile");
 
      console.log("profile data - " + JSON.stringify(data));
 
      if (data.status === false) {
      } else {
        //setUser(data.user);
        dispatch(profileAction(data.profile));
        console.log("profile data - " + JSON.stringify(data));
      }
    } catch (error) {}
  }

  useEffect(() => {
    loadKeys();
    setFilter(match.params.filter);
  }, [match]);

  const loadKeys = async () => {
    setLoading(true);
    const { data } = await axiosInstance.post("master-reseller/all-licenses", {
      filter: match.params.filter,
      is_trial: match.params.filter == "trial",
      product_id: product,
      search: search,
      page: page,
      limit: pageSize,
    });

    if (data.status) {
      setKeys(data.keys);
      var maxPage2 = Math.ceil(data.total / pageSize);
      console.log("max page - " + maxPage2);
      setMaxPage(maxPage2);
      setLoading(false);
    }
  };



  const loadCampaigns = async () => {
    const { data } = await axiosInstance.post("license/campaigns", {
      search: "",
    });

    if (data.status) {
      setCampaigns(data.campaigns);
      if (data.campaigns.length > 0) {
        setSelectedCampaign(data.campaigns._id);
        console.log("campaign - " + JSON.stringify(data.campaigns[0]));
        console.log("campaign id - " + data.campaigns[0]._id);
      }
    }
  };

  const initXlsData = async () => {
    const { data } = await axiosInstance.post("master-reseller/all-licenses", {
      filter: match.params.filter,
      product_id: product,
      search: search,
      page: 0,
      limit: maxPage * pageSize,
    });

    if (data.status) {
      console.log("total keys - " + data.keys.length);

      var dummy = [];
      data.keys.map((d, i) => {
        dummy.push({
          sr_no: i + 1,
          id: d.id,
          key: d.key,
          product_id: d.productId,
          product_name: productName(d.productId),
          valid: d.valid,
          status: d.status,
          name: d.name,
          phone: d.phone,
          email: d.email,
          place: d.place,
          country: d.country,
          activate_at: d.activateAt
            ? format(new Date(d.activateAt), "dd/MM/yyyy H:mma")
            : "",
          expire_at: d.expireAt
            ? format(new Date(d.expireAt), "dd/MM/yyyy H:mma")
            : "",
          note: d.note ?? "",
          created_at: format(new Date(d.createdAt), "dd/MM/yyyy H:mma"),
        });
      });

      setXlsData(dummy);
    }
  };

  function productName(id) {
    if (id) {
      const productIndex = products.findIndex((x) => x._id === id);

      if (productIndex != -1) {
        return products[productIndex].name;
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  const loadResellers = async () => {
    const { data } = await axiosInstance.post("master-reseller/resellers");

    if (data.status) {
      setResellers(data.resellers);
      if (data.resellers.length > 0) {
        setSelectedResellerId(data.resellers[0]._id);
      }
    }
  };

  const onEditLic = async (lic) => {
    setActiveKey(lic);
    setName(lic.name);
    setEmail(lic.email);
    setPhone(lic.phone);
    setCity(lic.place);
    setCountry(lic.country);
    setActiveDate(lic.activateAt ?? "");
    setExpireDate(lic.expireAt ?? "");
    setValid(lic.valid);
    setEnable(lic.enable);
    setStatus(lic.status);

    setNote(lic.note ?? "");

    setEditKeyModal(true);
  };

  const onUpdateLicDetails = async (event) => {
    event.preventDefault();

    let error = false;
    var errorObj = {};

    if (selectedProduct === "") {
      error = true;
      errorObj.product = "Product is Required";
    }

    if (status != "0" && activeDate == "") {
      error = true;
      errorObj.activeDate = "Activate Date is Required";
    }

    if (status != "0" && expireDate == "") {
      error = true;
      errorObj.expireAt = "Expire Date is Required";
    }

    setErrors(errorObj);
    if (!error) {
      try {
        const { data } = await axiosInstance.post("license/update-details", {
          license_id: activeKey.id,
          campaign_id: selectedCampaign,
          product_id: selectedProduct,
          enable: enable,
          status: Number(status),
          valid: Number(valid),

          activate_at: activeDate,
          expire_at: expireDate,

          name: name,
          email: email,
          phone: phone,
          place: city,
          country: country,

          note: note,
        });

        if (data.status === false) {
          //toast.error(data.message);
          swal("Update license", data.message, "error");
        } else {
          setEditKeyModal(false);
          //toast.success(data.message);
          swal("Update license", data.message, "success");

          setKeyQty("1");
          setValid("365");
          setCampaignName("");
          setNote("");
          setName("");
          setEmail("");
          setPhone("");
          setCity("");
          setCountry("");

          var dummy = [...keys];
          const index = dummy.findIndex((x) => x.id === activeKey.id);
          dummy[index].enable = enable;
          dummy[index].status = status;
          dummy[index].name = name;
          dummy[index].email = email;
          dummy[index].phone = phone;
          dummy[index].place = city;
          dummy[index].country = country;
          dummy[index].valid = valid;
          dummy[index].note = note;
          setKeys(dummy);
          //onClick(activePage);

          if (status == "0") {
            dummy[index].activateAt = null;
            dummy[index].expireAt = null;
          } else {
            dummy[index].activateAt = activeDate;
            dummy[index].expireAt = expireDate;
          }

          // setTimeout(() => {
          //   loadKeys();
          // }, 500);
        }
      } catch (error) {}
    }
  };

  function keyStatus(d) {
    if (d.status == 0) {
      return <Badge variant="success light">Available</Badge>;
    } else if (d.status == 1) {
      return <Badge variant="secondary">Active</Badge>;
    } else if (d.status == 2) {
      return <Badge variant="danger light">Deactive</Badge>;
    } else if (d.status == 3) {
      return <Badge variant="danger light">Expire</Badge>;
    } else {
      return <></>;
    }
  }

  const columns = [
    {
      Header: "SN",
      accessor: "_id",
      sort: false,
      accessor: "",

      Cell: (row) => {
        return <div>{Number(row.row.id) + page * pageSize + 1}</div>;
      },
    },
    {
      Header: "Key",
      accessor: "key",
      //colspan:2,
      //width: getColumnWidth(rows, 'key', 'Key'),
      //style: { width: '40%', textAlign: 'center' },
      Cell: ({ row }) => {
        const d = row.original;
        return (
          <span
            style={{ whiteSpace: "nowrap" }}
            onClick={() => {
              navigator.clipboard.writeText(d.key);
            }}
          >
            {d.key}
          </span>
        );
        //return <input  className="form-control" style={{ width: '40%', textAlign: 'center' }} type="text" value={d.key} readOnly={true}></input>;
      },
    },
    {
      Header: "User",
      accessor: "name",
      sort: false,
      Cell: ({ row }) => {
        const d = row.original;
        return (
          <div>
            {d.name}
            <br />
            <small>{d.phone}</small>
            <br />
            <small>{d.email}</small>
            <br />
            <small>{d.place}</small>
            <br />
            <small>{d.country}</small>
          </div>
        );
      },
    },
    {
      Header: "Reseller",
      accessor: "reserllerId",
      sort: false,
      Cell: ({ row }) => {
        const d = row.original;
        return d.resellerId ? d.resellerId.id == profile.id ? <Badge variant="success light">My Key</Badge> :  (
          <div>
            <Link to={"/reseller/" + d.resellerId.id}>{d.resellerId.name}</Link>
            <br />
            <small>{d.resellerId.phone}</small>
            <br />
            <small>{d.resellerId.email}</small>
          </div>
        ) : (
          <div>No</div>
        );
      },
    },

    {
      Header: "Product",
      accessor: "productId",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return productName(d.productId);
      },
    },

    {
      Header: "Campaign",
      accessor: "campaignId",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return d.campaignId ? d.campaignId.name : "";
      },
    },

    {
      Header: "Valid",
      accessor: "valid",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return d.valid + " Days";
      },
    },

    {
      Header: "Status",
      accessor: "status",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return <div className="text-center">{keyStatus(d)}</div>;
      },
    },

    {
      Header: "Enable",
      accessor: "enable",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return (
          <div className="text-center">
            {d.enable ? (
              <Badge variant="success light">Yes</Badge>
            ) : (
              <Badge variant="danger light">No</Badge>
            )}
          </div>
        );
      },
    },

    {
      Header: "Active",
      accessor: "activatedAt",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return d.activateAt
          ? format(new Date(d.activateAt), "dd/MM/yyyy H:mma")
          : "No";
      },
    },

    {
      Header: "Expire",
      accessor: "expireAt",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return d.expireAt
          ? format(new Date(d.expireAt), "dd/MM/yyyy H:mma")
          : "No";
      },
    },

    {
      Header: "Note",
      accessor: "note",
      sort: true,
    },

    {
      Header: "Created",
      accessor: "createdAt",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return d.createdAt
          ? format(new Date(d.createdAt), "dd/MM/yyyy H:mma")
          : "No";
      },
    },

    {
      Header: "Action",
      accessor: "",
      sort: false,
      Cell: ({ row }) => {
        const d = row.original;

        return (
          <Dropdown>
            <Dropdown.Toggle variant="" className="table-dropdown icon-false">
              <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <rect x="0" y="0" width="24" height="24"></rect>
                  <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                </g>
              </svg>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {d.enable ? (
                <Dropdown.Item
                  as="button"
                  onClick={(e) => onChangeLicStatus(d, false)}
                >
                  Disable
                </Dropdown.Item>
              ) : (
                <Dropdown.Item
                  as="button"
                  onClick={(e) => onChangeLicStatus(d, true)}
                >
                  Enable
                </Dropdown.Item>
              )}

              {d.status == 1 ? (
                <Dropdown.Item as="button" onClick={(e) => onRemoveDevice(d)}>
                  Remove Device
                </Dropdown.Item>
              ) : (
                <></>
              )}
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ];

  const onRemoveDevice = async (lic) => {
    const { data } = await axiosInstance.post(
      "master-reseller/remove-lic-device",
      {
        license_id: lic.id,
        reseller_id: lic.resellerId?lic.resellerId.id:""
      }
    );

    if (data.status === false) {
      swal("Remove Device", data.message, "error");
    } else {
      swal("Remove Device", data.message, "success");

      var dummy = [...keys];
      const index = dummy.findIndex((x) => x.id === lic.id);
      dummy[index].status = 0;
      setKeys(dummy);
    }
  };

  const onChangeLicStatus = async (lic, status) => {
    const { data } = await axiosInstance.post(
      "master-reseller/change-lic-status",
      {
        license_id: lic._id,
        reseller_id: lic.resellerId?lic.resellerId.id:"",
        status: status,
      }
    );

    if (data.status === false) {
      //toast.error(data.message);
      swal("Change license Status", data.message, "error");
    } else {
      swal("Change license Status", data.message, "success");
      // setTimeout(() => {
      //   loadKeys();
      // }, 500);

      var dummy = [...keys];

      const index = dummy.findIndex((x) => x._id === lic._id);

      dummy[index].enable = status;
      setKeys(dummy);
    }
  };


  useEffect(() => {
   
    console.log("selectedVariation - "+selectedVariation)

    var variation = variations.find((x) => x._id == selectedVariation);
    if (variation) {
     setTotalPoint(variation.price * Number(keyCount))
    } else {
      setTotalPoint(0)
    }
  
}, [selectedVariation,keyCount]);


  const onTransferKeys = async () => {
    setSelectedResellerId({
      value:"me",
      label:"My Self"
    });
    setTransferModal(true);
  };


  useEffect(() => {
    if (selectedProduct != "select") {

      console.log("all product size - "+products.length)

      var product = products.find((x) => x._id == selectedProduct || x.id == selectedProduct);
      if (product) {
        console.log("product found - "+JSON.stringify(product))
        if ((product.variations ?? []).length > 0) {
          setvariations(product.variations);
          setSelectedVariation(product.variations[0]._id)
          setEnableTransfer(true);
        } else {
          setEnableTransfer(false);
        }
      } else {
        console.log("product not found")
        setEnableTransfer(false);
      }
    } else {
      setEnableTransfer(false);
    }
  }, [selectedProduct]);

  const onTransferKeysSubmit = async (event) => {
    event.preventDefault();
    setTransferLoading(true)
    let error = false;
    var errorObj = {};
 
    if (selectedProduct === "select") {
      error = true;
      errorObj.product = "Please select product first";
    }else if (keyCount == "" || keyCount == "0") {
       error = true;
       errorObj.keys = "Keys is not valid!";
     }
    
    if (!enableTransfer) {
      error = true;
      swal("Transfer Keys", "You can't transfer this product license keys!", "warning");
      return;
    }else if(totalPoint > (profile.wallet??0)){
      swal("Transfer Keys", "Insufficient balance for transfer keys!", "warning");
      return;
    }
 
    setErrors(errorObj);
 
    if (!error) {
       try {
         const { data } = await axiosInstance.post(
           "master-reseller/transfer-keys",
           {
             keys: keyCount,
             reseller_id: selectedResellerId.value,
             product_id: selectedProduct,
             variation_id:selectedVariation
           }
         );
        
         if (data.status === false) {
           swal("Transfer Keys", data.message, "error");
         } else {
          
           swal("Transfer Keys", data.message, "success");
 
           setSelectedProduct("select");
           setvariations([]);
           setTotalPoint(0)
           setKeyCount(0);
           setTransferModal(false)
 
           setTimeout(() => {
             loadProfile();
           }, 500);
         }
         setTransferLoading(false)
       } catch (error) {
        setTransferLoading(false)
       }
     }
 
  };

  return (
    <>

<Modal
        className="modal fade"
        show={transferModal}
        onHide={setTransferModal}
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={onTransferKeysSubmit}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Transfer Keys To</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setTransferModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">



                  
                  <div className="form-group mb-3">
                      <label className="text-black font-w500">Reseller</label>
                      <div className="contact-name">


                        
                          <Select
                            
                            defaultValue={selectedResellerId}
                            value={selectedResellerId}
                            onChange={(option) => setSelectedResellerId(option)}
                           
                            options={[{
                              value: "me",
                              label: "My Self",
                            },...resellers.map((user, i) => {
                              return {
                                value: user._id,
                                label: user.name + "(" + user.phone + ")",
                              };
                            })]}
                          >
                            
                          </Select>
                       
                        {errors.reseller && (
                          <div className="text-danger fs-12">
                            {errors.reseller}
                          </div>
                        )}
                      </div>
                    </div>




                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Product</label>
                      <select
                        className="form-control"
                        value={selectedProduct}
                        onChange={(e) => setSelectedProduct(e.target.value)}
                      >
                        <option value="select">Choose Product</option>
                        {products
                          .filter((x) => x.enable == true)
                          .map((p, i) => {
                            return (
                              <option key={i} value={p.id??p._id}>
                                {i + 1} - {p.name}
                              </option>
                            );
                          })}
                      </select>

                      {errors.product && (
                              <div className="text-danger fs-12">
                                {errors.product}
                              </div>
                            )}

                    </div>

                    {selectedProduct == "select" ? (
                      <></>
                    ) : !enableTransfer ? (
                      <Alert className="mt-4" variant={"warning"}>
                        You can't transfer this product license keys!
                      </Alert>
                    ) : (
                      <>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">
                            Validity
                          </label>
                          <select
                            className="form-control"
                            value={selectedVariation}
                            onChange={(e) =>
                              setSelectedVariation(e.target.value)
                            }
                          >
                            {variations.map((p, i) => {
                              return (
                                <option key={i} value={p._id}>
                                  {p.title}
                                </option>
                              );
                            })}
                          </select>
                        </div>

                        <div className="form-group mb-3">
                          <label className="text-black font-w500">
                            Total Keys
                          </label>
                          <div className="contact-name">
                            <input
                              type="number"
                              value={keyCount}
                              className="form-control"
                              autocomplete="off"
                              min={1}
                              required="required"
                              onChange={(e) => setKeyCount(Math.abs(e.target.value))}
                              placeholder="Total Keys"
                            />
                            {errors.keys && (
                              <div className="text-danger fs-12">
                                {errors.keys}
                              </div>
                            )}

                            <span className="validation-text"></span>
                          </div>
                        </div>

                        <div className="form-group mb-3">
                          <ul class="list-group mb-3">
                            <li class="list-group-item d-flex justify-content-between lh-condensed">
                              <div>
                                <h6 class="my-0">Your Wallet Balance</h6>
                               
                              </div>
                              <span class="text-muted">{ profile.wallet??0}</span>
                            </li>
                            
                            <li class="list-group-item d-flex justify-content-between">
                              <span>Total Point</span>
                              <strong>{totalPoint}</strong>
                            </li>
                          </ul>
                        </div>

                        {totalPoint > (profile.wallet??0) ?   <Alert className="mt-4" variant={"warning"}>
                        Insufficient balance for transfer keys!
                      </Alert>:<></>}

                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setTransferModal(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
                {transferLoading? <Spinner animation="border" variant="primary" />:<button type="submit" className="btn btn-primary">
                  Transfer
                </button>}
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Fragment>
        {/* <PageTitle activeMenu="Datatable" motherMenu="Table" /> */}

        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title mr-auto">Licenses Keys</h4>

              <div className="input-group search-area d-lg-inline-flex d-none mr-2">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (searchTimeout) {
                      clearTimeout(searchTimeout);
                    }
                    loadCampaigns();
                  }}
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </form>
                <div className="input-group-append">
                  <span className="input-group-text">
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"
                        fill="#A4A4A4"
                      />
                    </svg>
                  </span>
                </div>
              </div>


              <Button
                  className="btn btn-primary font-w600 mr-2"
                  onClick={() => onTransferKeys()}
                >
                  Transfer Keys
                </Button>

            

              <CSVLink
                data={xlsData}
                filename={"keys-" + match.params.filter + ".csv"}
                className="btn btn-primary"
                target="_blank"
                asyncOnClick={true}
                onClick={async (event, done) => {
                  console.log("You click the link");
                  await initXlsData();
                  done(); // 👍🏻 You are stopping the handling of component
                }}
              >
                Export
              </CSVLink>
            </div>
            <div className="card-body">
              {loading ? (
                <div className="text-center mt-4">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : keys.length == 0 ? (
                <p>No License Keys Found</p>
              ) : (
                <DTable
                  columns={columns}
                  data={keys}
                  pageSize={pageSize}
                  isSortable={true}
                  pagination={false}
                  pageSizes={pageSizes}
                  page={page}
                  maxPage={maxPage}
                  handlePageChange={handlePageChange}
                  handlePageSizeChange={handlePageSizeChange}
                />

                /*  <Table responsive className="w-100">
                  <div id="example_wrapper" className="dataTables_wrapper">
                    <table id="example" className="display w-100 dataTable">
                      <thead>
                        <tr role="row">
                          <th>Sr No</th>
                          <th>Key</th>
                          <th>User</th>
                          <th>Reseller</th>
                          <th>Product</th>
                          <th>Campaign</th>
                          <th>Valid</th>
                          <th>Status</th>
                          <th>Enable</th>
                          <th>Active</th>
                          <th>Expire</th>
                          <th>Note</th>
                          <th>Created</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((d, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td
                              style={{ whiteSpace: "nowrap" }}
                              onClick={() => {
                                navigator.clipboard.writeText(d.key);
                              }}
                            >
                              {d.key}
                            </td>
                            <td>
                              {d.name}
                              <br />
                              <small>{d.phone}</small>
                              <br />
                              <small>{d.email}</small>
                              <br />
                              <small>{d.place}</small>
                              <br />
                              <small>{d.country}</small>
                            </td>

                            {d.resellerId ? (
                              <td>
                                <Link to={"/reseller/" + d.resellerId.id}>
                                  {d.resellerId.name}
                                </Link>
                                <br />
                                <small>{d.resellerId.phone}</small>
                                <br />
                                <small>{d.resellerId.email}</small>
                              </td>
                            ) : (
                              <td>No</td>
                            )}

                            <td>{productName(d.productId)}</td>

                            <td>{d.campaignId ? d.campaignId.name : ""}</td>

                            <td>{d.valid} Days</td>
                            <td className="text-center">{keyStatus(d)}</td>

                            <td className="text-center">
                              {d.enable ? (
                                <Badge variant="success light">Yes</Badge>
                              ) : (
                                <Badge variant="danger light">No</Badge>
                              )}
                            </td>

                            <td>
                              {d.activateAt
                                ? format(
                                    new Date(d.activateAt),
                                    "dd/MM/yyyy H:mma"
                                  )
                                : "No"}{" "}
                            </td>

                            <td>
                              {d.expireAt
                                ? format(
                                    new Date(d.expireAt),
                                    "dd/MM/yyyy H:mma"
                                  )
                                : "No"}{" "}
                            </td>

                            <td>{d.note ?? ""}</td>

                            <td>
                              {d.createdAt
                                ? format(
                                    new Date(d.createdAt),
                                    "dd/MM/yyyy H:mma"
                                  )
                                : "No"}{" "}
                            </td>

                            <td>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant=""
                                  className="table-dropdown icon-false"
                                >
                                  <svg
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth="1"
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      ></rect>
                                      <circle
                                        fill="#000000"
                                        cx="5"
                                        cy="12"
                                        r="2"
                                      ></circle>
                                      <circle
                                        fill="#000000"
                                        cx="12"
                                        cy="12"
                                        r="2"
                                      ></circle>
                                      <circle
                                        fill="#000000"
                                        cx="19"
                                        cy="12"
                                        r="2"
                                      ></circle>
                                    </g>
                                  </svg>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  {d.enable ? (
                                    <Dropdown.Item
                                      as="button"
                                      onClick={(e) =>
                                        onChangeLicStatus(d, false)
                                      }
                                    >
                                      Disable
                                    </Dropdown.Item>
                                  ) : (
                                    <Dropdown.Item
                                      as="button"
                                      onClick={(e) =>
                                        onChangeLicStatus(d, true)
                                      }
                                    >
                                      Enable
                                    </Dropdown.Item>
                                  )}

                                  <Dropdown.Item
                                    as="button"
                                    onClick={(e) => assignReseller(d)}
                                  >
                                    {d.resellerId
                                      ? "Change Reseller"
                                      : "Assign to Reseller"}
                                  </Dropdown.Item>

                                  {d.resellerId ? (
                                    <Dropdown.Item
                                      as="button"
                                      onClick={(e) => removeResellerFromKey(d)}
                                    >
                                      Remove Reseller
                                    </Dropdown.Item>
                                  ) : (
                                    <></>
                                  )}

                                  <Dropdown.Item
                                    as="button"
                                    onClick={(e) => onEditLic(d)}
                                  >
                                    Edit
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr role="row">
                          <th>Sr No</th>
                          <th>Key</th>
                          <th>User</th>
                          <th>Reseller</th>
                          <th>Product</th>
                          <th>Campaign</th>
                          <th>Valid</th>
                          <th>Status</th>
                          <th>Enable</th>
                          <th>Active</th>
                          <th>Expire</th>
                          <th>Note</th>
                          <th>Created</th>
                          <th>Action</th>
                        </tr>
                      </tfoot>
                    </table>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                      <div className="dataTables_info">
                        Showing {activePage * sort + 1} to &nbsp;
                        {keys.length < (activePage + 1) * sort
                          ? keys.length
                          : (activePage + 1) * sort}
                        &nbsp;of {keys.length} entries
                      </div>
                      <div className="dataTables_paginate paging_simple_numbers">
                        <Pagination
                          className="pagination-primary pagination-circle"
                          size="lg"
                        >
                          <li
                            className="page-item page-indicator "
                            onClick={() =>
                              activePage > 1 && onClick(activePage - 1)
                            }
                          >
                            <Link className="page-link" to="#">
                              <i className="la la-angle-left" />
                            </Link>
                          </li>
                          {pagination.map((number, i) => (
                            <Pagination.Item
                              key={"page-" + i}
                              className={activePage === i ? "active" : ""}
                              onClick={() => onClick(i)}
                            >
                              {number}
                            </Pagination.Item>
                          ))}
                          <li
                            className="page-item page-indicator"
                            onClick={() =>
                              activePage + 1 < pagination.length &&
                              onClick(activePage + 1)
                            }
                          >
                            <Link className="page-link" to="#">
                              <i className="la la-angle-right" />
                            </Link>
                          </li>
                        </Pagination>
                      </div>
                    </div>
                  </div>
                </Table> */
              )}
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default LicenseKeys;
