import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Dropdown, Button, Modal, Table, Form, Badge } from "react-bootstrap";

import PageTitle from "../../layouts/PageTitle";

import { SRLWrapper } from "simple-react-lightbox";

import { useDispatch, useSelector } from "react-redux";

import axiosInstance from "../../../services/AxiosInstance";

import swal from "sweetalert";

import FormData from "form-data";
import { getAllProducts } from "../../../store/actions/licActions";
import { set } from "date-fns";

const Setting = () => {
  const products = useSelector((state) => state.lic.products);
  const [selectedProduct, setSelectedProduct] = useState("select");

  const [activeToggle, setActiveToggle] = useState("posts");
  const [sendMessage, setSendMessage] = useState(false);
  const [replay, setReplay] = useState(false);

  const [productData, setProductData] = useState([]);
  const [test, setTest] = useState(true);

  //product setting
  const [productSettingModal, setProductSettingModal] = useState(false);
  const [activeProduct, setActiveProduct] = useState(null);
  const [productName, setProductName] = useState("");
  const [productEnable, setProductEnable] = useState(false);

  const [websiteUrl, setWebsiteUrl] = useState("");
  const [downloadPageUrl, setDownloadPageUrl] = useState("");

  const dispatch = useDispatch();

  let errorsObj = {
    name: "",
    description: "",
    androidUpdate: "",
    iosUpdate: "",
    androidVersion: "",
    iosVersion: "",
    appStoreUrl: "",
    playStoreUrl: "",
    newsUpdate: "",
    frontImage: "",
    frontLink: "",
    backImage: "",
    backLink: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [androidUpdate, setAndroidUpdate] = useState(true);
  const [androidVersion, setAndroidVersion] = useState("");
  const [playStoreUrl, setPlayStoreUrl] = useState("");
  const [newsUpdate, setNewsUpdate] = useState("");
  const [frontImage, setFrontImage] = useState("");
  const [frontLink, setFrontLink] = useState("");
  const [backImage, setBackImage] = useState("");
  const [backLink, setBackLink] = useState("");

  //const [profile, setProfile] = useState({});

  const options = {
    settings: {
      overlayColor: "#000000",
    },
  };

  useEffect(() => {
    if (products) {
      loadProductSetting();
    }

    // setProductData( products.map((x) => {
    //  return {
    //     id:x._id,
    //     original:x.name,
    //     name: "",
    //     enable: false,
    //   }
    // }));
  }, [products]);

  useEffect(() => {
    loadDetails();
    dispatch(getAllProducts());
  }, [dispatch]);

  const loadProductSetting = async () => {
    const { data } = await axiosInstance.post(
      "master-reseller/product-setting"
    );

    if (data.status) {
      setProductData(
        products.map((x) => {
          const product = data.products.find((p) => p.productId == x._id);

          if (product) {
            return {
              id: x._id,
              original: x.name,
              name: product.name,
              enable: product.enable,
            };
          } else {
            return {
              id: x._id,
              original: x.name,
              name: "",
              enable: true,
            };
          }
        })
      );
    } else {
      setProductData(
        products.map((x) => {
          return {
            id: x._id,
            original: x.name,
            name: "",
            enable: false,
          };
        })
      );
    }

    console.log("details data - " + JSON.stringify(data));
  };

  const loadDetails = async () => {
    const { data } = await axiosInstance.get("auth/profile");

    if (data.profile) {
      const profile = data.profile;
      setWebsiteUrl(profile.websiteUrl ?? "");
      setDownloadPageUrl(profile.downloadPageUrl ?? "");
    }

    console.log("details data - " + JSON.stringify(data));
  };

  const onProductSettingEdit = (product) => {
    setActiveProduct(product);
    setProductName(product.name);
    setProductEnable(product.enable);
    setProductSettingModal(true);
  };

  const onProductSettingSubmit = async (event) => {
    event.preventDefault();
    let error = false;
    var errorObj = {};

    if (productName === "") {
      error = true;
      errorObj.name = "Product name is required!";
    }

    setErrors(errorObj);

    if (!error) {
      try {
        const { data } = await axiosInstance.post(
          "master-reseller/save-product-setting",
          {
            product_id: activeProduct.id,
            name: productName,
            enable: productEnable,
          }
        );

        if (data.status === false) {
          swal("Save Product Setting", data.message, "error");
        } else {
          setProductSettingModal(false);
          swal("Save Product Setting", data.message, "success");

          setSelectedProduct(null);
          setProductName("");
          setProductEnable(true);

          setTimeout(() => {
            loadProductSetting();
          }, 500);
        }
      } catch (error) {}
    }
  };

  const onSaveStoreSetting = async (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (websiteUrl === "") {
      errorObj.websiteUrl = "Website Url is Required";
      error = true;
    }

    if (downloadPageUrl === "") {
      errorObj.downloadPageUrl = "Download Page Url is Required";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      console.log("error on save");
      return;
    }

    const formData = {
      website_url: websiteUrl,
      download_page_url: downloadPageUrl,
    };

    console.log("form data - " + formData);

    const { data } = await axiosInstance.post(
      "master-reseller/save-store-setting",
      formData
    );

    if (data.status) {
      swal("Update Store Setting", data.message, "success");
    } else {
      swal("Update Store Setting", data.message, "error");
    }
  };

  return (
    <>
      <Modal
        className="modal fade"
        show={productSettingModal}
        onHide={setProductSettingModal}
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={onProductSettingSubmit}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Custom Product Name</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setProductSettingModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">
                        Product Name
                      </label>

                      <input
                        type="text"
                        className="form-control"
                        autocomplete="off"
                        value={productName}
                        onChange={(e) => setProductName(e.target.value)}
                        placeholder="Enter Name"
                      />
                      {errors.name && (
                        <div className="text-danger fs-12">{errors.name}</div>
                      )}
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Enable</label>

                      <Form.Check
                        type="checkbox"
                        label={``}
                        //value={product.enable}
                        checked={productEnable}
                        onClick={(e) => setProductEnable(e.target.checked)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setProductSettingModal(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Fragment>
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body">
                <div id="">
                  <div className="pt-3">
                    <div className="settings-form">
                      <h4 className="text-primary">Store Setting</h4>

                      <form onSubmit={onSaveStoreSetting}>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label>Website Url</label>
                            <input
                              type="url"
                              value={websiteUrl}
                              onChange={(e) => setWebsiteUrl(e.target.value)}
                              placeholder="https://"
                              className="form-control"
                            />
                            {errors.websiteUrl && (
                              <div className="text-danger fs-12">
                                {errors.websiteUrl}
                              </div>
                            )}
                          </div>
                          <div className="form-group col-md-6">
                            <label>Download Page Url</label>
                            <input
                              type="url"
                              value={downloadPageUrl}
                              onChange={(e) => setDownloadPageUrl(e.target.value)}
                              placeholder="https://"
                              className="form-control"
                            />
                            {errors.downloadPageUrl && (
                              <div className="text-danger fs-12">
                                {errors.downloadPageUrl}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="text-right">
                        <button className="btn btn-sm btn-primary" type="submit">
                          Save
                        </button>
                        </div>
                        
                      </form>

                      <h4 className="text-primary">Product Setting</h4>

                      <Table responsive>
                        <thead>
                          <tr>
                            <th>
                              <strong>SR NO.</strong>
                            </th>
                            <th>
                              <strong>Product Name</strong>
                            </th>
                            <th>
                              <strong>Your Product Name</strong>
                            </th>

                            <th>
                              <strong>Enable</strong>
                            </th>

                            <th>
                              <strong>Action</strong>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {productData.map((product, i) => (
                            <tr key={i}>
                              <td>
                                <strong>{i + 1}</strong>
                              </td>

                              <td>{product.original}</td>
                              <td>
                                <strong>{product.name}</strong>
                              </td>

                              <td>
                                {product.enable ? (
                                  <Badge variant="success light">Yes</Badge>
                                ) : (
                                  <Badge variant="danger light">No</Badge>
                                )}
                              </td>

                              <td>
                                <Button
                                  className="btn btn-sm btn-primary"
                                  onClick={(e) => onProductSettingEdit(product)}
                                >
                                  Edit
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default Setting;
