const initialData = {
	products : [],
	statistics:{}
}
const licenseReducers = (state = initialData, action) =>{
	switch(action.type){
		case "PRODUCTS" : 
		
		return{
			...state,
			products:action.payload
		}

		case "STATISTICS" : 
		
		return{
			...state,
			statistics:action.payload
		}
		
		default: return state;
	}
}
export default licenseReducers;