import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { nanoid } from "nanoid";
import xlsx from "xlsx";
import Moment from "moment";
import { format } from "date-fns";

import { useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CSVLink } from "react-csv";

import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  Modal,
  ProgressBar,
  Button,
  Spinner,
  Alert,
} from "react-bootstrap";

/// imge
import avatar1 from "../../../images/avatar/1.jpg";
import avatar2 from "../../../images/avatar/2.jpg";
import avatar3 from "../../../images/avatar/3.jpg";

import {
  inviteMemberAction,
  bulkInviteMemberAction,
  cancelInvitationAction,
  getInvitationsAction,
  resetAllAction,
} from "../../../store/actions/MemberActions";

import axiosInstance from "../../../services/AxiosInstance";

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

import PhoneInput from "react-phone-number-input";
import { getAllProducts } from "../../../store/actions/licActions";

import { profileAction } from "../../../store/actions/AuthActions";

const MemberPage = ({ props, history }) => {
  const dispatch = useDispatch();

  const profile = useSelector((state) => state.auth.profile);

  //Transfer Keys
  const products = useSelector((state) => state.lic.products);
  const [selectedProduct, setSelectedProduct] = useState("select");
  const [variations, setvariations] = useState([]);
  const [selectedVariation, setSelectedVariation] = useState(null);
  const [enableTransfer, setEnableTransfer] = useState(false);
  const [totalPoint, setTotalPoint] = useState(0);
  const [keys, setKeys] = useState(1);
  const [price, setPrice] = useState(0);

  const [transferLoading, setTransferLoading] = useState(false);

  //End Transfer Keys

  const [transferModal, setTransferModal] = useState(false);
  const [loading, setLoading] = useState(false);

  //const members = useSelector((state) => state.members.invitations);
  const [members, setMembers] = useState([]);

  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [xlsData, setXlsData] = useState([]);

  const [addMemberModal, setAddMemberModal] = useState(false);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  let errorsObj = { name: "", email: "", phone: "" };
  const [errors, setErrors] = useState(errorsObj);

  const [editMemberModal, setEditMemberModal] = useState(false);


  //const [activeMember, setActiveMember] = useState(null);

  useEffect(() => {
    if (selectedProduct != "select") {

      console.log("all product size - "+products.length)

      var product = products.find((x) => x._id == selectedProduct || x.id == selectedProduct);
      if (product) {
        console.log("product found - "+JSON.stringify(product))
        if ((product.variations ?? []).length > 0) {
          setvariations(product.variations);
          setSelectedVariation(product.variations[0]._id)
          setEnableTransfer(true);
        } else {
          setEnableTransfer(false);
        }
      } else {
        console.log("product not found")
        setEnableTransfer(false);
      }
    } else {
      setEnableTransfer(false);
    }
  }, [selectedProduct]);


  useEffect(() => {
   
     console.log("selectedVariation - "+selectedVariation)

     var variation = variations.find((x) => x._id == selectedVariation);
     if (variation) {
      setTotalPoint(variation.price * Number(keys))
     } else {
       setTotalPoint(0)
     }
   
 }, [selectedVariation,keys]);


  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    setSearchTimeout(
      setTimeout(() => {
        loadUsers();
      }, 1000)
    );

    return () => clearTimeout(searchTimeout);
  }, [search]);

  const loadUsers = async () => {
    const { data } = await axiosInstance.post("master-reseller/resellers", {
      search: search,
    });

    if (data.status) {
      setMembers(data.resellers);
    }
  };

  useEffect(() => {
    if (members) {
      setLoading(false);
    }
  }, [members]);

  //edit profile
  const [activeMember, setActiveMember] = useState("");

  //const shareTitle = "pvc2print invite";
  //const shareBody = "pvc2print";

  //const { invitations} = getInvitations;
  useEffect(() => {
    loadUsers();
    loadProfile();
    dispatch(getAllProducts());
  }, [dispatch]);

  function onEditMember(member) {
    setActiveMember(member.id);
    setPhone(member.phone);
    setEmail(member.email);
    setName(member.name);
    setEditMemberModal(true);
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const onSaveMember = async (event) => {
    event.preventDefault();
    let error = false;
    var errorObj = {};

    setTransferLoading(true)

    if (name === "") {
      error = true;
      errorObj.name = "Name is Required";
    }

    if (email === "") {
      error = true;
      errorObj.email = "Email is Required";
    }else if (!validateEmail(email)) {
      error = true;
      errorObj.email = "Enter Valid Email!";
    }

    setErrors(errorObj);
    if (!error) {
      try {
        const { data } = await axiosInstance.post(
          "master-reseller/update-reseller",
          {
            name: name,
            email: email,
            user_id: activeMember,
          }
        );

        if (data.status === false) {
          swal("Update Reseller", data.message, "error");
        } else {
          setEditMemberModal(false);
          swal("Update Reseller", data.message, "success");

          setPhone("");
          setEmail("");
          setName("");

          setTimeout(() => {
            loadUsers();
          }, 500);
        }
        setTransferLoading(false)
      } catch (error) {
        setTransferLoading(false)
      }
    }
  };

  const onAddMember = async (event) => {
    event.preventDefault();
    let error = false;
    var errorObj = {};

    if (name === "") {
      error = true;
      errorObj.name = "Name is Required";
    }

    if (phone === "") {
      error = true;
      errorObj.phone = "Phone Number is Required";
    }

    if (email === "") {
      error = true;
      errorObj.email = "Email is Required";
    }else if (!validateEmail(email)) {
      error = true;
      errorObj.email = "Enter Valid Email!";
    }

    setErrors(errorObj);
    if (!error) {
      try {
        const { data } = await axiosInstance.post(
          "master-reseller/add-reseller",
          {
            name: name,
            phone: phone,
            email: email,
          }
        );

        if (data.status === false) {
          //toast.error(data.message);
          swal("Add Reseller", data.message, "error");
        } else {
          setAddMemberModal(false);
          //toast.success(data.message);
          swal("Add Reseller", data.message, "success");

          setPhone("");
          setEmail("");
          setName("");

          setTimeout(() => {
            loadUsers();
          }, 500);
        }
      } catch (error) {}
    }
  };

  const initXlsData = async () => {
    var dummy = [];
    members.map((data, i) => {
      dummy.push({
        sr_no: i + 1,
        name: data.name,
        phone: data.phone,
        email: data.email,
        created_at: format(new Date(data.createdAt), "dd/MM/yyyy H:mma"),
      });
    });

    setXlsData(dummy);
  };

  const onTransferKeys = async (member) => {
    setActiveMember(member);
    setTransferModal(true);
  };

  
  const onTransferKeysSubmit = async (event) => {
   event.preventDefault();
   let error = false;
   var errorObj = {};

   setTransferLoading(true)

   if (selectedProduct === "select") {
     error = true;
     errorObj.product = "Please select product first";
   }else if (Number(keys??"0") <= 0 ) {
      error = true;
      errorObj.keys = "Keys is not valid!";
    }
   
   if (!enableTransfer) {
     error = true;
     swal("Transfer Keys", "You can't transfer this product license keys!", "warning");
     return;
   }else if(totalPoint > (profile.wallet??0)){
     swal("Transfer Keys", "Insufficient balance for transfer keys!", "warning");
     return;
   }

   setErrors(errorObj);

   if (!error) {
      try {
        const { data } = await axiosInstance.post(
          "master-reseller/transfer-keys",
          {
            keys: keys,
            reseller_id: activeMember.id,
            product_id: selectedProduct,
            variation_id:selectedVariation
          }
        );

        if (data.status === false) {
          swal("Transfer Keys", data.message, "error");
        } else {
          setEditMemberModal(false);
          swal("Transfer Keys", data.message, "success");

          setSelectedProduct("select");
          setvariations([]);
          setTotalPoint(0)
          setKeys(0);
          setTransferModal(false)

          setTimeout(() => {
            loadUsers();
            loadProfile();
          }, 500);
        }

        setTransferLoading(false)
      } catch (error) {
        setTransferLoading(false)
      }
    }

 };

 async function loadProfile() {
   try {
     const { data } = await axiosInstance.get("auth/profile");

     console.log("profile data - " + JSON.stringify(data));

     if (data.status === false) {
     } else {
       //setUser(data.user);
       dispatch(profileAction(data.profile));
       console.log("profile data - " + JSON.stringify(data));
     }
   } catch (error) {}
 }

  return (
    <>
      <Modal
        className="modal fade"
        show={transferModal}
        onHide={setTransferModal}
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={onTransferKeysSubmit}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Transfer Keys</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setTransferModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Product</label>
                      <select
                        className="form-control"
                        value={selectedProduct}
                        onChange={(e) => setSelectedProduct(e.target.value)}
                      >
                        <option value="select">Choose Product</option>
                        {products
                          .filter((x) => x.enable == true)
                          .map((p, i) => {
                            return (
                              <option key={i} value={p.id??p._id}>
                                {i + 1} - {p.name}
                              </option>
                            );
                          })}
                      </select>

                      {errors.product && (
                              <div className="text-danger fs-12">
                                {errors.product}
                              </div>
                            )}

                    </div>

                    {selectedProduct == "select" ? (
                      <></>
                    ) : !enableTransfer ? (
                      <Alert className="mt-4" variant={"warning"}>
                        You can't transfer this product license keys!
                      </Alert>
                    ) : (
                      <>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">
                            Validity
                          </label>
                          <select
                            className="form-control"
                            value={selectedVariation}
                            onChange={(e) =>
                              setSelectedVariation(e.target.value)
                            }
                          >
                            {variations.map((p, i) => {
                              return (
                                <option key={i} value={p._id}>
                                  {p.title}
                                </option>
                              );
                            })}
                          </select>
                        </div>

                        <div className="form-group mb-3">
                          <label className="text-black font-w500">
                            Total Keys
                          </label>
                          <div className="contact-name">
                            <input
                              type="number"
                              value={keys}
                              className="form-control"
                              autocomplete="off"
                              min={1}
                              required="required"
                              onChange={(e) => setKeys(Math.abs(e.target.value))}
                              placeholder="Total Keys"
                            />
                            {errors.keys && (
                              <div className="text-danger fs-12">
                                {errors.keys}
                              </div>
                            )}

                            <span className="validation-text"></span>
                          </div>
                        </div>

                        <div className="form-group mb-3">
                          <ul class="list-group mb-3">
                            <li class="list-group-item d-flex justify-content-between lh-condensed">
                              <div>
                                <h6 class="my-0">Your Wallet Balance</h6>
                               
                              </div>
                              <span class="text-muted">{ profile.wallet??0}</span>
                            </li>
                            
                            <li class="list-group-item d-flex justify-content-between">
                              <span>Total Point</span>
                              <strong>{totalPoint}</strong>
                            </li>
                          </ul>
                        </div>

                        {totalPoint > (profile.wallet??0) ?   <Alert className="mt-4" variant={"warning"}>
                        Insufficient balance for transfer keys!
                      </Alert>:<></>}

                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setTransferModal(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
                {transferLoading? <Spinner animation="border" variant="primary" />  : <button type="submit" className="btn btn-primary">
                  Transfer
                </button>}
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Modal
        className="modal fade"
        show={addMemberModal}
        onHide={setAddMemberModal}
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={onAddMember}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Add Reseller</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setAddMemberModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Name</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          value={name}
                          className="form-control"
                          autocomplete="off"
                          name="name"
                          required="required"
                          onChange={(e) => setName(e.target.value)}
                          placeholder="Name"
                        />
                        {errors.name && (
                          <div className="text-danger fs-12">{errors.name}</div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Email</label>
                      <div className="contact-name">
                        <input
                          type="email"
                          value={email}
                          className="form-control"
                          autocomplete="off"
                          name="name"
                          required="required"
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Email"
                        />
                        {errors.email && (
                          <div className="text-danger fs-12">
                            {errors.email}
                          </div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">
                        Phone Number
                      </label>
                      <div className="contact-name">
                        <PhoneInput
                          placeholder="Enter phone number"
                          value={phone}
                          defaultCountry="IN"
                          onChange={setPhone}
                        />

                        {errors.phone && (
                          <div className="text-danger fs-12">
                            {errors.phone}
                          </div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setAddMemberModal(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
                <button type="submit" className="btn btn-primary">
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Modal
        className="modal fade"
        show={editMemberModal}
        onHide={setEditMemberModal}
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={onSaveMember}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Edit Reseller</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setEditMemberModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Name</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          value={name}
                          className="form-control"
                          autocomplete="off"
                          name="name"
                          required="required"
                          onChange={(e) => setName(e.target.value)}
                          placeholder="Name"
                        />
                        {errors.name && (
                          <div className="text-danger fs-12">{errors.name}</div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Email</label>
                      <div className="contact-name">
                        <input
                          type="email"
                          value={email}
                          className="form-control"
                          autocomplete="off"
                          name="name"
                          required="required"
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Email"
                        />
                        {errors.email && (
                          <div className="text-danger fs-12">
                            {errors.email}
                          </div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">
                        Phone Number
                      </label>
                      <div className="contact-name">
                        <PhoneInput
                          placeholder="Enter phone number"
                          value={phone}
                          defaultCountry="IN"
                          disabled
                          readOnly
                          onChange={setPhone}
                        />

                        {errors.phone && (
                          <div className="text-danger fs-12">
                            {errors.phone}
                          </div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setEditMemberModal(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title className="mr-auto p-2">Resellers</Card.Title>

              <div className="input-group search-area d-lg-inline-flex d-none mr-2">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (searchTimeout) {
                      clearTimeout(searchTimeout);
                    }
                    dispatch(getInvitationsAction({ search: search }));
                  }}
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </form>
                <div className="input-group-append">
                  <span className="input-group-text">
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"
                        fill="#A4A4A4"
                      />
                    </svg>
                  </span>
                </div>
              </div>

              <div class="d-flex justify-content-end">
                <Link
                  className="btn btn-light font-w600 mr-2"
                  onClick={() => setAddMemberModal(true)}
                >
                  Add Reseller
                </Link>

                <CSVLink
                  data={xlsData}
                  filename={"members.csv"}
                  className="btn btn-primary"
                  target="_blank"
                  asyncOnClick={true}
                  onClick={async (event, done) => {
                    console.log("You click the link");
                    await initXlsData();
                    done(); // 👍🏻 You are stopping the handling of component
                  }}
                >
                  Export
                </CSVLink>
              </div>
            </Card.Header>
            <Card.Body>
              {loading ? (
                <div className="text-center mt-4">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : members.length == 0 ? (
                <p>No Reseller Found</p>
              ) : (
                <Table responsive>
                  <thead>
                    <tr>
                      <th>
                        <strong>SR NO.</strong>
                      </th>
                      <th>
                        <strong>Name</strong>
                      </th>
                      <th>
                        <strong>Phone</strong>
                      </th>
                      <th>
                        <strong>Email</strong>
                      </th>

                      <th>
                        <strong>Joined At</strong>
                      </th>

                      <th>
                        <strong>Action</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {members.map((invitation, i) => (
                      <tr key={i}>
                        <td>
                          <strong>{i + 1}</strong>
                        </td>
                        <td>

                        <Link to={"/reseller/" + invitation._id}>{invitation.name}</Link>
                          
                        </td>
                        <td>{invitation.phone} </td>
                        <td>{invitation.email} </td>

                        <td>
                          {format(
                            new Date(invitation.createdAt),
                            "dd/MM/yyyy H:mma"
                          )}
                        </td>

                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant=""
                              className="table-dropdown icon-false"
                            >
                              <svg
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                version="1.1"
                              >
                                <g
                                  stroke="none"
                                  strokeWidth="1"
                                  fill="none"
                                  fillRule="evenodd"
                                >
                                  <rect
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                  ></rect>
                                  <circle
                                    fill="#000000"
                                    cx="5"
                                    cy="12"
                                    r="2"
                                  ></circle>
                                  <circle
                                    fill="#000000"
                                    cx="12"
                                    cy="12"
                                    r="2"
                                  ></circle>
                                  <circle
                                    fill="#000000"
                                    cx="19"
                                    cy="12"
                                    r="2"
                                  ></circle>
                                </g>
                              </svg>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                as="button"
                                onClick={(e) => onTransferKeys(invitation)}
                              >
                                Transfer Keys
                              </Dropdown.Item>

                              <Dropdown.Item
                                as="button"
                                onClick={(e) => onEditMember(invitation)}
                              >
                                Edit Profile
                              </Dropdown.Item>

                              {/*  <div className="dropdown-divider"></div>
                                                <Dropdown.Item
                                                   href="#"
                                                   className="text-danger"
                                                >
                                                   Delete
                                                </Dropdown.Item> */}
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default MemberPage;
